//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContentList from '../components/common/contentList';

import AboutUs from '../components/sections/about-us';
import Plan from '../components/sections/plan';
import MainFormSection from '../components/mainPage/mainFormSection';

// const sbjs = require('sourcebuster');

export default {
  name: 'MainPage',
  components: {
    ContentList,
    ReviewsSection: () => import('../components/reviewsSection.vue'),
    AboutUs,
    Plan,
    MainFormSection,
  },
  scrollToTop: false,
  data() {
    return {
      heroes: [
        // TODO: https://webdesign.tutsplus.com/ru/tutorials/quick-tip-how-to-use-html5-picture-for-responsive-images--cms-21015
        {
          mobile: 'hero-1-mobile',
          desktop: 'hero-1-desktop',
        },
        {
          mobile: 'hero-2-mobile',
          desktop: 'hero-2-desktop',
        },
        {
          mobile: 'hero-3-mobile',
          desktop: 'hero-3-desktop',
        },
        {
          mobile: 'hero-4-mobile',
          desktop: 'hero-4-desktop',
        },
      ],
      currentHero: {},

      dialogName: '',
      dialogPhone: '',
      dialogPrivacyPolicy: true,

      upsellDialog: false,

      loading: {
        form: false,
        dialogForm: false,
      },
    };
  },
  async fetch() {
    // Blogs
    if (this.$app.config.current.withBlog) {
      if (this.$app.config.current.tildaBlog) {
        let responseBlogs;
        try {
          responseBlogs = await this.$axios({
            method: 'get',
            url: this.$app.config.current.getBlogsUrl,
          });
        } catch (e) {
          console.error(e);
          // error({ statusCode: 500, message: 'Os trabalhos técnicos estão em andamento' });
          // throw e;
        }

        let blogs = [];
        if (responseBlogs?.data) {
          if (responseBlogs.data.status !== 'ok') {
            throw new Error(
              `An error occurred while executing the query (GetBlogs)`,
            );
          }

          blogs = responseBlogs.data.items || [];

          if (this.$app.config.current.newDomainName) {
            blogs = blogs.map((blog) => ({
              ...blog,
              item_image_full:
                (blog.item_image_full &&
                  blog.item_image_full.replace(
                    new URL(blog.item_image_full).origin,
                    this.$app.config.current.newDomainName,
                  )) ||
                '',
              item_link_full:
                (blog.item_link_full &&
                  blog.item_link_full.replace(
                    new URL(blog.item_link_full).origin,
                    this.$app.config.current.newDomainName,
                  )) ||
                '',
            }));
          }
        }

        this.$store.commit('mainPage/setBlogs', Object.assign([], blogs));
      } else {
        let responseBlogs;
        if (this.$app.config.current.code === 'mx') {
          try {
            responseBlogs = await this.$axios.get(
              `${this.$app.config.current.wordPressPosts}`,
              {
                params: {
                  offset: 0,
                  per_page: 4,
                },
              },
            );

            let blogs = [];
            if (responseBlogs?.data) {
              blogs = (responseBlogs.data || []).map((blog) => ({
                ...blog,
                formattedCreated: this.$dayjs(blog.created).format(
                  'DD.MM.YYYY',
                ),
              }));
            }

            this.$store.commit('mainPage/setBlogs', Object.assign([], blogs));
          } catch (e) {
            console.error(e);
          }
        }
      }
    }

    // Webinars
    let responseWebinars;
    try {
      responseWebinars = await this.$axios({
        method: 'get',
        url: `${this.$app.config.current.CMS_REST_API}/public/v2/events/`,
        params: {
          limit: 4,
          offset: 0,
          directions: [],
          published: true,
        },
      });
    } catch (e) {
      console.error(e);
      // error({ statusCode: 500, message: 'Os trabalhos técnicos estão em andamento' });
      // throw e;
    }

    let webinars = [];
    if (responseWebinars) {
      if (responseWebinars.data.error) {
        throw new Error(
          `An error occurred while executing the query (${responseWebinars.data.error})`,
        );
      }

      webinars = responseWebinars.data.data.data;
    }

    this.$store.commit('mainPage/setWebinars', Object.assign([], webinars));
  },
  head() {
    return {
      title: this.$t('meta.index.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.index.description'),
        },
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: `https://${this.$app.config.current.domain}${this.$route.path}`,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.$t('meta.index.title'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.$t('meta.index.description'),
        },
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:image',
          name: 'og:image',
          property: 'og:image',
          content: `https://${
            this.$store?.state?.host
          }${require(`~/assets/${this.$app?.config?.common?.currentCountry}/ebac-logo.png`)}`,
        },
      ],
    };
  },
  async created() {
    this.currentHero =
      this.heroes[Math.floor(Math.random() * this.heroes.length)];
    // Решили попап с формой не показывать
    // if (process.browser) {
    //   const upsellDate = localStorage.getItem('upsellDate');
    //   if (!upsellDate || moment().diff(moment(upsellDate), 'days') > 5) {
    //     setTimeout(() => {
    //       localStorage.setItem('upsellDate', moment().toISOString());
    //       this.upsellDialog = true;
    //     }, 1000 * 30);
    //   }
    // }
  },
  methods: {
    async onSubmitDialog() {
      // Решили попап с формой не показывать
      // if (this.loading.dialogForm) return;
      //
      // this.loading.dialogForm = true;
      // const isValid = await this.$refs.dialogForm.validate();
      //
      // if (!isValid) {
      //   this.loading.dialogForm = false;
      //   return;
      // }
      //
      // const formData = new FormData(this.$refs.dialogForm.$el.querySelector('form'));
      // formData.append('name', this.dialogName);
      // formData.append('phone', this.dialogPhone);
      // formData.append('privacyPolicy', this.dialogPrivacyPolicy);
      //
      // try {
      //   const response = await this.$axios({
      //     method: 'post',
      //     url: 'https://gdd.ebaconline.com.br/exec',
      //     data: urlencodeFormData(formData),
      //     headers: {
      //       'Content-Type': `application/x-www-form-urlencoded`,
      //     },
      //   });
      //
      //   this.upsellDialog = false;
      //   this.successDialog = true;
      //
      //   this.$refs.dialogForm.reset();
      //   this.dialogName = '';
      //   this.dialogPhone = '';
      //
      //   this.dialogPrivacyPolicy = true;
      //
      //   this.$gtm.push({ 'event': 'autoEvent', 'eventCategory': 'lead', 'eventAction': 'index_footer' });
      // } catch (e) {
      //   console.error(e);
      // }
      //
      // this.loading.dialogForm = false;
    },
  },
};
