//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    sortNew: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    if (!this.sortNew) {
      let nomenclatures = this.$store.state.mainPage.nomenclatures;
      if (!nomenclatures.length) {
        nomenclatures = await this.query();
      }
      this.$store.commit(
        'mainPage/setNomenclatures',
        Object.assign([], nomenclatures),
      );
    } else {
      let nomenclatures = this.$store.state.mainPage.newNomenclatures;
      if (!nomenclatures.length) {
        nomenclatures = await this.query({
          sort: 'BySortDescShortName',
          showOnMain: false,
        });
      }
      this.$store.commit(
        'mainPage/setNewNomenclatures',
        Object.assign([], nomenclatures),
      );
    }
  },
  computed: {
    text_h2() {
      return !this.sortNew
        ? 'index.main-courses-section.h2'
        : 'index.main-courses-section--new.h2';
    },
    text_subtitle() {
      return !this.sortNew
        ? 'index.main-courses-section.subtitle'
        : 'index.main-courses-section--new.subtitle';
    },
    nomenclatures() {
      if (!this.sortNew) return this.$store.state.mainPage.nomenclatures;
      else return this.$store.state.mainPage.newNomenclatures;
    },
  },
  methods: {
    async query(filter) {
      let response;
      const data = {
        limit: 6,
        offset: 0,
        showOnMain: true,
        country: this.$app?.config?.common?.currentCountry,
        ...filter,
      };
      if (
        ['pe', 'co', 'ar', 'cl'].includes(
          this.$app?.config?.common?.currentCountry,
        )
      ) {
        data.latam = true;
      }
      try {
        response = await this.$axios({
          method: 'GET',
          url: `${this.$app.config.current.CMS_REST_API}/public/v2/showcase/`,
          params: data,
        });
      } catch (e) {
        console.error(e);
      }

      let nomenclatures = [];
      if (response) {
        if (response.data.error) {
          throw new Error(
            `An error occurred while executing the query (${response.data.error})`,
          );
        }
        nomenclatures = response.data.data.data;
      }

      if (this.$app.config.current.newDomainName) {
        return nomenclatures.map((nomenclature) => ({
          ...nomenclature,
          pageUrl:
            (nomenclature.pageUrl &&
              nomenclature.pageUrl.replace(
                new URL(nomenclature.pageUrl).origin,
                this.$app.config.current.newDomainName,
              )) ||
            '',
        }));
      } else {
        return nomenclatures;
      }
    },
  },
};
