import meta from './meta';
export default {
  ...meta,
  'direction.courses': 'Todos',
  'direction.coursesMobile': 'Todos las áreas',
  'direction.0.metaDescription':
    'Los cursos online de EBAC, son impartidos por profesionistas reconocidos en sus áreas de especialización dentro del diseño y la tecnología.',
  'direction.0.metaTitle':
    'Cursos online con certificado internacional  — EBAC Online',
  'direction.0.seoText':
    'Cursos online con certificado impartidos por profesores expertos, reconocidos en sus áreas de especialización, quienes les compartirán todos los secretos de las profesiones digitales.',
  'direction.0.title': 'Cursos online',
  day: '0 días | 1 día | {days} días',
  month: 'no meses | {count} mes | {count} meses',
  'types.any': 'Todos',
  'types.course': 'Curso',
  'types.profession': 'Profesión',
  required: 'Campo obligatorio',
  min: 'Longitud de campo incorrecta',
  email: 'Dirección de correo electrónico incorrecta',
  comingSoon: 'Proximamente',
  currencySign: 'MXN',
  less: 'menos',
  more: 'mais',
  presentationHeadline: 'La oportunidad que estabas buscando',
  loading: 'Cargando...',

  // template
  'template.login': 'Soy Alumno',
  'template.contract': 'Contrato de prestación de servicios',
  'template.privacyPolicy': 'Política de privacidad',
  'template.termsAndConditions': 'Términos y condiciones',
  'template.privacyNoticeStudents': 'Aviso de Privacidad Alumnos',
  'template.privacyNoticeTeachers': 'Aviso de Privacidad Profesores',
  'template.cursos': 'Cursos online en Brasil',
  'template.phone': 'Teléfono',
  'template.email': 'E-mail',
  'template.aboutUs': 'Sobre nosotros',
  'template.careersCenter': 'Centro de Carreras',
  'template.jobs': 'Vacantes',
  'template.b2b': 'Para empresas',
  'template.design': 'Diseño',
  'template.audioVisual': 'Audiovisual',
  'template.marketing': 'Marketing',
  'template.gaming': 'Gaming',

  course: 'cursos | curso | cursos',
  profession: 'profesiones | profesión | profesiones',

  // mainSearch component
  'mainSearch.placeholder': 'Busca',
  'mainSearch.search': 'Search',
  'mainSearch.clear': 'Clear search',

  // mainNavigation component
  'mainNavigation.aboutUs': 'Sobre nosotros',
  'mainNavigation.careerCenter': 'Centro de Carreras',
  'mainNavigation.jobs': 'Vacantes',

  // phone component
  'phone.error': 'Escribe tu teléfono',
  'phone.other': 'Otro',
  'phone.placeholder': 'Teléfono',

  // firstSection component
  'firstSection.aboutUs': 'Sobre',

  // Partners section
  'partnersSection.h2': 'Nuestros socios comerciales',
  'partnersSection.text':
    'Los convenios entre EBAC y socios del sector público y privado permiten la creación de programas educativos basados ​​en las demandas actuales y futuras del mercado, proporcionando a los estudiantes la experiencia de trabajar con proyectos reales.',

  // additionalNavigation component
  'additionalNavigation.careerCenterStudents': 'Para Alumnos',
  'additionalNavigation.careerCenterCompany': 'Para empresas',

  // filtersContent component
  'filtersContent.courseType': 'Tipo de curso',
  'filtersContent.courseType.profession': 'Profesión',
  'filtersContent.courseType.profession.text':
    'Programa a largo plazo con formación integral que proporciona un dominio de todos los aspectos del área elegida. Este programa te ayudará a construir tu cartera, crear tu currículum y prepararte para garantizar una colocación en el mercado laboral.',
  'filtersContent.courseType.course': 'Curso',
  'filtersContent.courseType.course.text':
    'Programa a corto plazo que te permitirá ingresar a una especialización en el área elegida, aprendiendo habilidades específicas desde el nivel básico hasta el avanzado que utilizarás para tu portafolio y crecimiento profesional.',
  'filtersContent.EBACAdvantages': 'EBAC Vantagens',
  'filtersContent.jobGuarantee': 'Garantía de empleo',
  'filtersContent.duration': 'Duración',
  'filtersContent.duration.from': 'De',
  'filtersContent.duration.to': 'a',
  'filtersContent.duration.months': 'meses',
  'filtersContent.free': 'Curso gratuito',
  'filtersContent.topSale': 'Curso más vendido',

  // basket component
  'basket.mobileHeader': 'Cursos escolhidos',

  // index page
  'index.main-hero-section.h1': 'EBAC Online',
  'index.main-courses-section.profissao': 'Profesión',
  'index.main-courses-section.curso': 'Curso',
  'index.main-hero-section.h2': 'Los mejores cursos para acelerar tu carrera',
  'index.main-hero-section.directions.title':
    'Checa nuestras diferentes áreas:',
  'index.main-hero-section.directions.comingSoonDirections': 'Proximamente:',
  'index.main-courses-section.h2': 'Cursos Online',
  'index.main-courses-section.subtitle':
    'Programas en formato de video lecciones, ideal para quienes buscan destacar en el mercado laboral y quieren aprender nuevas habilidades para evolucionar profesionalmente.',
  'index.main-courses-section.btn': 'VER TODOS LOS CURSOS',
  'index.main-webinars-section.h2': 'Webinars Gratuitos',
  'index.main-webinars-section.subtitle':
    'Grandes profesionales exponen temas relevantes y su impacto en el mercado laboral en transmisiones en vivo.',
  'index.main-webinars-section.btn': 'Ver todos los webinars',
  'index.main-blog-section.h2': 'Blog',
  'index.main-blog-section.btn': 'Ver todos los blogs',
  'index.main-reviews-section.h2': 'Testimonio de los alumnos',
  'index.main-plan-section.h2': '¿Cómo es el proceso de los cursos?',
  'index.main-plan-section.1.h3': 'Aprende sobre el tema',
  'index.main-plan-section.1.description':
    'El curso consiste en lecciones en video detalladas y muy fáciles de entender',
  'index.main-plan-section.2.h3': 'Pon tu aprendizaje en práctica',
  'index.main-plan-section.2.description':
    'Cuentas con actividades para practicar a tu propio ritmo',
  'index.main-plan-section.3.h3': 'Intercambia conocimientos con el tutor',
  'index.main-plan-section.3.description':
    'Recibes corrección y retroalimentación personalizada sobre tus trabajos',
  'index.main-plan-section.4.h3': 'Crea un proyecto final propio',
  'index.main-plan-section.4.description':
    'Conocimientos que te ayudarán a desarrollar un proyecto individual para enriquecer tu portafolio',
  'index.main-info-section.description':
    'La Escuela Británica de Artes Creativas y Tecnología (EBAC) es una institución innovadora de educación superior en Artes Creativas y Tecnología que ofrece cursos y programas de especialización en línea.',
  'index.main-info-section.1.title': '+45',
  'index.main-info-section.1.description':
    'сursos profesionales en el área de IT, diseño, marketing, audiovisual, fashion y negocios',
  'index.main-info-section.1.description.mx':
    'сursos profesionales en el área de IT, diseño, marketing, audiovisual, fashion y negocios',
  'index.main-info-section.2.title': '+95000',
  'index.main-info-section.2.description':
    'alumnos estudiando nuestros cursos online',
  'index.main-info-section.3.title': '100%',
  'index.main-info-section.3.description':
    'de los profesores y coordinadores son profesionales en el mercado laboral',
  'index.main-info-section.4.title': '+40',
  'index.main-info-section.4.description':
    'empresas colaboran en el desarrollo de cursos, proyectos reales y programas de prácticas',
  'index.main-form-section.h2': 'Regístrate',
  'index.main-form-section.description':
    'Regístrate en nuestra lista de correos y recibe noticias sobre cursos en línea, invitaciones a eventos y webinars gratuitos.',
  'index.main-form-section.name.label': 'Nombre',
  'index.main-form-section.name.error': 'Escribe tu nombre',
  'index.main-form-section.email.label': 'Email',
  'index.main-form-section.email.error': 'Escribe tu e-mail',
  'index.main-form-section.privacy-policy.part1':
    'Al hacer clic en enviar aceptas los términos y condiciones de la',
  'index.main-form-section.privacy-policy.part2': 'Política de privacidad',
  'index.main-form-section.btn': 'Enviar',
  'index.register.error.email': 'Escribe tu email',

  // about-us
  'about-us.title': 'Sobre nosotros',
  'about-us.description-part-1':
    'La Escuela Británica de Artes Creativas y Tecnología es una institución educativa innovadora que ofrece cursos, especializaciones y diplomados en línea. Cuenta con un cuerpo docente formado por excelentes profesionales de México y del exterior, nuestra escuela ofrece un modelo nuevo de enseñanza para disciplinas creativas con diploma internacional, utilizando las mejores tecnologías disponibles y garantizando la inserción del estudiante en el mercado laboral.',
  'about-us.description-part-2':
    'EBAC tiene una alianza con la Universidad de Hertfordshire, una de las más reconocidas del Reino Unido, y la mejor infraestructura con recursos de última generación para que nuestros estudiantes aprendan de lo más nuevo en el mercado.',
  'about-us.areas.h2': 'Áreas de enseñanza',
  'about-us.areas.card-item-1.title': 'Diseño',
  'about-us.areas.card-item-1.description':
    'Desarrolla proyectos de UX / UI, diseño de interiores, diseño gráfico, paisajismo, iluminación, entre otros áreas',
  'about-us.areas.card-item-2.title': 'Audiovisual',
  'about-us.areas.card-item-2.description':
    'Se un profesional que trabaje en sets de filmación, edición de video y efectos visuales',
  'about-us.areas.card-item-3.title': 'Marketing',
  'about-us.areas.card-item-3.description':
    'Domina el conocimiento del marketing digital y aprende sobre diferentes estrategias online',
  'about-us.areas.card-item-4.title': 'Software',
  'about-us.areas.card-item-4.description':
    'Avanza en tu carrera con conocimientos técnicos en algún software específico',
  'about-us.areas.card-item-5.title': 'Programación & Data',
  'about-us.areas.card-item-5.description':
    'Aprenda los lenguajes de programación, códigos, algoritmos y gestión de datos',
  'about-us.areas.card-item-6.title': 'Gamming',
  'about-us.areas.card-item-6.description':
    'Gestiona proyectos de gamming, desarrollar juegos, personajes y escenarios en 2D y 3D, con interactividad',
  'about-us.list.item-1.title': 'Aprende en la práctica',
  'about-us.list.item-1.description':
    'Formación completa a través del método "Aprender haciendo", combinando teoría y práctica',
  'about-us.list.item-2.title': 'Feedback profesional',
  'about-us.list.item-2.description':
    'Recibe el seguimiento necesario y la retroalimentación individualizada de nuestros tutores',
  'about-us.list.item-3.title': 'Actualización constante ',
  'about-us.list.item-3.description':
    'Contenido actualizado para mantenerte al día de las principales tendencias del mercado',
  'about-us.list.item-4.title': 'Portafolio & certificación',
  'about-us.list.item-4.title-without-wrap': 'Portafolio & certificación',
  'about-us.list.item-4.description':
    'Crea tu propio portafolio con proyectos de derechos de autor y obten un certificado de finalización',
  'about-us.reviews.h2': 'Testimonio de los alumnos',
  'about-us.places.h2': 'Estudiantes en México y el mundo',
  'about-us.places.h2-without-wrap': 'Estudiantes en México y el mundo',
  'about-us.places.list.item-1': 'São Paulo',
  'about-us.places.list.item-2': 'Curitiba',
  'about-us.places.list.item-3': 'Rio de Janeiro',
  'about-us.places.list.item-4': 'Porto Alegre',
  'about-us.places.list.item-5': 'Recife',
  'about-us.places.list.item-6': 'Miami',
  'about-us.places.list.item-7': 'Brasília',
  'about-us.places.list.item-8': 'Porto',
  'about-us.places.list.item-9': 'Salvador',
  'about-us.places.list.item-10': 'Florianópolis',
  'about-us.places.list.item-11': 'Belo Horizonte',
  'about-us.places.list.item-12': 'Belém',
  'about-us.places.list.item-13': 'Los Angeles',
  'about-us.places.list.item-14': 'Lisboa',
  'about-us.places.list.item-15': 'Manaus',
  'about-us.places.list.item-16': 'Natal',
  'about-us.places.list.item-17': 'Guadalajara',
  'about-us.places.list.item-18': 'Monterrey',
  'about-us.places.list.item-19': 'CDMX',

  'about-us.experts.title': 'Nuestros profesores son de primera clase',
  'about-us.experts.description':
    'En EBAC, el profesorado está formado por especialistas con experiencia práctica y actual en los mercados en los que trabajan. Todos ellos han sido reconocidos con premios y proyectos para clientes de alto perfil.',

  // career-center-students page
  'career-center-students.h2': 'Centro de carreras para alumnos',
  'career-center-students.description.h3': '"¿Termine mi curso y ahora?"',
  'career-center-students.description.subtitle':
    '¡EBAC trabaja para que alcances tus metas!',
  'career-center-students.description.body-m':
    'Estamos 100% enfocados en la trayectoria de nuestros estudiantes no solo a lo largo de sus estudios, sino también a la hora de ingresar al mercado laboral. Nuestro centro de carrera está conformado por un equipo de profesionales que trabajan en varios frentes para el futuro de quienes estudian con nosotros.',
  'career-center-students.step-1.title':
    'Reclutadores profesionales te ayudarán a estructurar tu currículum y prepararte para las entrevistas',
  'career-center-students.step-1.description':
    'Contamos con un equipo de especialistas en recursos humanos con amplia experiencia en reclutamiento y selección en diferentes sectores dispuestos a compartir sus conocimientos.<br><br>Trabajaremos contigo en los principales temas como la estructuración de tu currículum y perfil de LinkedIn, inseguridades al momento de las entrevistas, mapeo de habilidades para identificar tus principales fortalezas, entre otros aspectos de los procesos de selección.',
  'career-center-students.step-2.title':
    'Tu proyecto final es creado en colaboración con nuestras empresas colaboradoras',
  'career-center-students.step-2.description':
    'EBAC está ampliando constantemente su red de socios comerciales. Son empresas para las que están interesados los alumnos y donde, al finalizar sus proyectos de finalización basados ​​en casos reales, pueden trabajar. Los estudiantes con los mejores proyectos son evaluados por las empresas y pueden comenzar una nueva etapa en su vida profesional ya empleados.',
  'career-center-students.step-3.title':
    'Selección de las mejores vacantes para nuestros estudiantes',
  'career-center-students.step-3.description':
    'Monitoreamos en tiempo real las vacantes disponibles en el mercado laboral con el fin de presentar los currículos y portafolios de nuestros estudiantes a los equipos de selección.',
  'career-center-students.step-4.title':
    'Mentoría con reclutadores y profesionales de las empresas más soñadas por los candidatos',
  'career-center-students.step-4.description':
    'Invitamos a los reclutadores de las empresas más deseadas a participar en nuestros webinars. Compartirán sus secretos: lo que más notan durante el proceso de selección, tipos de evaluación, planes de carrera y más.<br><br>Por lo tanto, ¡Tu puedes enfocarte en tus clases! Estudia, responde tus dudas con los tutores, participa en nuestros webinars y haz un proyecto final excepcional. ¡Esto ya es una buena parte del camino!<br><br>El resto nosotros te ayudamos. ¡Lograremos a que tu carrera despegue!',

  // career-center-company page
  'career-center-company.h2': 'Centro de carreras para empresas',
  'career-center-company.description':
    'EBAC ayudará a su empresa a ampliar la base de candidatos y facilita la contratación de profesionales de los mercados creativo y tecnológico de forma gratuita. De esta forma fortalecemos tu marca empleadora y colaboramos en el desarrollo de talentos internos. Vea como:',

  'career-center-company.block-1.title': 'Reclutamiento',
  'career-center-company.block-1.description':
    'Nuevos estudiantes completan nuestros cursos semanalmente, creando así una plataforma sólida de candidatos potenciales para diversas oportunidades.<br><br>Ayudamos a su empresa a encontrar el candidato ideal de acuerdo con su cultura corporativa y posibles necesidades comerciales.',
  'career-center-company.block-1.list-1.content':
    'Comparta las vacantes disponibles a EBAC',
  'career-center-company.block-1.list-2.content':
    'Alineé con nosotros el perfil deseado y los principales requisitos',
  'career-center-company.block-1.list-3.content':
    'Reciba currículums y portafolios de los candidatos que mejor se adapten al perfil de cada oportunidad',

  'career-center-company.block-2.title': '¡Todo esto, gratis!',
  'career-center-company.block-2.description':
    'Su caso de negocio será el proyecto final de nuestros alumnos. Así, tendrán contacto directo con su empresa para entender el día a día como parte del equipo y también contribuir al negocio.',
  'career-center-company.block-2.list-1.content':
    'Describa su necesidad o problema para compartirlo con los estudiantes',
  'career-center-company.block-2.list-2.content':
    'Ellos (los estudiantes) trabajarán en base a su caso para el proyecto final',
  'career-center-company.block-2.list-3.content':
    'Presentamos los proyectos y alumnos con mejores resultados',

  'career-center-company.accent-block.part1':
    'Además de ser un proceso completamente gratuito, tu empresa puede ganar, invirtiendo en la imagen de empleador que tienes en el mercado.',
  'career-center-company.accent-block.part2':
    'Podemos promover los resultados y beneficios de esta asociación en nuestras redes sociales, además de utilizar nuestros webinars para aumentar la visibilidad y el interés en su marca.',

  'career-center-company.block-3.title': 'Desarrollo de talento interno',
  'career-center-company.block-3.description':
    '¿Necesita desarrollar el talento interno, implementar programas de pasantías y prácticas o promover la recalificación de los empleados dentro de su organización? Podemos ayudarte a elegir un curso adecuado para tus empleados o adaptarlo según las necesidades específicas de cada área de la empresa.',
  'career-center-company.block-3.list-1.content':
    'Elija el área de su negocio que necesita impulsar el desarrollo y promoción del talento',
  'career-center-company.block-3.list-2.content':
    'Describe tus metas para cada área o departamento',
  'career-center-company.block-3.list-3.content':
    'Promueva adecuadamente acciones educativas personalizadas para las áreas indicadas',

  // vacancies page
  'vacancies.h2': 'Vacantes',
  'vacancies.description':
    'EBAC enseña a las personas las profesiones más modernas y demandadas del mercado. Todos nuestros esfuerzos están dirigidos a mejorar la calidad de la enseñanza y ampliar el alcance de nuestros cursos. Esto solo sucede gracias a nuestro equipo de profesionales dedicados y comprometidos con la idea de crear una plataforma de enseñanza en línea que motive a querer a estudiar de verdad.',
  'vacancies.vacanciesListTitle':
    'Si deseas unirse al equipo de EBAC, consulta nuestras oportunidades abiertas aquí:',
  'vacancies.noVacancies.part1':
    'Oops, parece que no tenemos vacantes disponibles en este momento.',
  'vacancies.noVacancies.part2':
    'Envíanos tu currículum y nos pondremos en contacto en cuanto surjan nuevas oportunidades.',
  'vacancies.emailText':
    'Si no ha identificado ninguna oportunidad abierta en su área de especialización, infórmenos por correo electrónico:',
  'vacancies.email': 'reclutamiento@ebac.mx',
  'vacancies.directions.0': 'Todos las vacantes',

  // courses page
  'courses.professions': 'Profesiones',
  'courses.courses': 'Cursos',
  'courses.profession': 'Profesión',
  'courses.course': 'Curso',
  'courses.more': 'más',
  'courses.notFound': 'No encontramos resultados para tu búsqueda',
  'courses.postGraduate': 'Programa de posgrado',

  'courses.title': 'Cursos online',

  'courses.filter.emptyResults': 'No encontramos resultados para tu búsqueda',

  'courses.filter.directions.items.todos': 'Todos',

  'courses.filter.search.placeholder': 'Busca aquí',
  'courses.filter.types.title': 'Tipo de curso',

  'courses.filter.types.items.todos': 'Todos',
  'courses.filter.types.items.course': 'Curso',
  'courses.filter.types.items.profession': 'Profesión',

  'courses.filter.duration.title': 'Duración',
  'courses.filter.topics.title': 'Tópico', // TODO Translate
  'courses.filter.topics.more': '{count} mais tópicos', // TODO Translate
  'courses.filter.topics.less': 'Colapso', // TODO Translate
  'courses.filter.topics.search': 'Pesquise por tópicos', // TODO Translate

  'courses.filter.checkOurTopSaleCourses':
    'Hmm... ¿No encuentras lo que buscas? Tal vez está en nuestros cursos más populares:',

  'vantagens.title': 'Ventajas EBAC',
  'vantagens.jobGuarantee': 'Garantía de empleo',
  'vantagens.topSale': 'Curso más vendido',
  'vantagens.free': 'Curso gratuito',
  'vantagens.soldOut': 'Agotado',

  'courses.form.title.1': 'Regístrate',
  'courses.form.title.2': '',
  'courses.form.text':
    'Regístrate en nuestra lista de correos y recibe noticias sobre cursos en línea, invitaciones a eventos y webinars gratuitos.',
  'courses.form.placeholder.name': 'Nombre',
  'courses.form.placeholder.email': 'Email',
  'courses.form.sendButton': 'Enviar',

  'courses.filter.selected':
    'Filtros | {count} filtro seleccionada | {count} filtros seleccionados',

  'courses.cards.profession.title': 'Profesiones',
  'courses.cards.course.title': 'Cursos',
  'courses.cards.default.action.main': 'Más sobre el curso', // TODO Deepl

  'courses.cards.sale.action.main': 'Añadir', // TODO Deepl
  'courses.cards.sale.action.additional': 'Más sobre el curso', // TODO Deepl

  'courses.cards.badges.type.0': 'Curso',
  'courses.cards.badges.type.1': 'Profesión',

  'courses.cards.duration': '{duration} meses',
  'courses.cards.more': 'Ver más',

  'courses.filter.mobile.toggler': 'Filtro',
  'courses.filter.mobile.heading': 'Filtro',
  'courses.filter.mobile.apply': 'Aplicar',

  'index.success-dialog.title': '¡Gracias!',
  'index.success-dialog.description':
    'Gracias por tu inscripción. No dejes de checar el spam.',
  'index.success-dialog.btn': 'Ok',

  'filtersContent.filter': 'Filtro',
  'filtersContent.apply': 'Aplicar',

  // sale event types
  'sale.event_type.webinar': 'DEL WEBINAR',
  'sale.event_type.marathon': 'DEL MARATÓN',
  'sale.event_type.workshop': 'DEL WORKSHOP',
  'sale.event_type.discussion': 'DE CONSEJOS DE CARRERA',
  'sale.event_type.demonstration': 'DE LA DEMOSTRACIÓN',
  'sale.event_type.success_case': 'DE CASOS DE ÉXITO',
  'sale.event_type.masterclass': 'DA MASTERCLASS',
  'sale.event_type.immersion': 'DE INMERSIÓN',
  'sale.nomenclature_type.0': 'Curso',
  'sale.nomenclature_type.1': 'Profesión',
  'sale.nomenclature_type.0.en': 'Course',
  'sale.nomenclature_type.1.en': 'Profession',

  acceptTheTermsAndConditions: 'Eu li e aceito os termos e condições da',
  privacyPolicyAndServiceAgreement:
    'Política de Privacidade e Contrato de Prestação de Serviço',

  'webinarSale.badge.title': 'del descuento',
  'webinarSale.first.title': 'Oferta exclusiva a los participantes',
  'webinarSale.first.subtitle': 'Promoción válida por tiempo limitado',
  'webinarSale.timer.title': 'La promoción termina en:',
  'webinarSale.timer.days': 'Días',
  'webinarSale.timer.hours': 'Horas',
  'webinarSale.timer.minutes': 'Minutos',
  'webinarSale.timer.seconds': 'Segundos',
  'webinarSale.second.subtitle': '¡{value} de descuento!',
  'webinarSale.price.first.title': 'Precio sin descuento',
  'webinarSale.price.first.economy': 'Ahorras',
  'webinarSale.price.second.title': 'Precio con descuento',
  'webinarSale.price.second.subtitle': 'a meses sin intereses en la tarjeta',
  'webinarSale.sendButton': 'Aprovechar descuento',
  'webinarSale.dialog.title': 'Inscríbete al curso',
  'webinarSale.dialog.field.name.label': 'Nombre',
  'webinarSale.dialog.field.name.error': 'Digite seu nome',
  'webinarSale.dialog.field.email.error': 'Digite seu e-mail',
  'webinarSale.dialog.sendButton': 'Inscríbete ahora',

  'webinarSale.special.title': '¡Oferta especial!',
  'webinarSale.special.subtitle.1':
    'Descuento disponible hasta el\xa0último día del evento en\xa0vivo.',
  'webinarSale.special.subtitle.2':
    'Puedes utilizar este descuento en\xa0el\xa0curso que elijas.',
  webinarsCount:
    '{count}er día | {count}do día | {count}to día | {count}mo día | {count}vo día | {count}no día | {count}o día',

  'thankYouPage.title': '¡Gracias!',
  'thankYouPage.description.part1':
    'Gracias por tu inscripción. Pronto recibirás un correo con más detalles.',
  'thankYouPage.description.part2': '(Ah! No dejes de checar el spam)',
  'thankYouPage.description.part3': '¿Quieres finalizar tu registro ahora?',
  'thankYouPage.description.part4':
    'Haz tu pago online. Es fácil, rápido y seguro.',
  'thankYouPage.btn.label': 'Completar el registro',
  'thankYouPage.btn.loading': 'Cargando...',

  // Offers
  'offer.metaTitle': '¡Oferta personalizada! — EBAC Online',
  'offer.title': '¡Oferta personalizada!',
  'offer.description':
    'Para conseguir un precio especial en todos los cursos, abona el importe haciendo clic en el botón "junto al pago". Después de eso, el acceso a tu cuenta personal llegará por correo electrónico.',
  'offer.orderId': 'Ordem {id}',
  'offer.courses': 'Cursos',
  'offer.payAll': 'Junto al pago',
  'offer.pay': 'Pagar',
  'offer.price.courses': 'Cursos ({count})',
  'offer.price.discount': 'Descuento',
  'offer.price.total': 'Total',

  'offer.paymentProgress': 'Progreso del pago',
  'offer.paymentLink.part': 'Parte {index}',
  'offer.payment.status.success': 'Pagado',
  'offer.paymentSuccess.title': '¡Felicidades!',
  'offer.paymentSuccess.text':
    'El curso está totalmente pagado. En cinco minutos recibirás un correo electrónico con un nombre de usuario y una contraseña para acceder a tu cuenta personal.',
  'offer.paymentСanceled.title': 'La oferta ha sido cancelada.',
  'offer.paymentСanceled.text': '',

  'offer.paymentLinksNotFound': 'Links de pago no encontrados',

  'offer.payment.successPage.title': 'Payment Successful!',
  'offer.payment.successPage.description.part1':
    'Your payment has been made, you will receive an email shortly with more details.',
  'offer.payment.successPage.description.part2':
    '(Ah! Be sure to check your spam folder)',
  'offer.payment.successPage.description.part3':
    'Perhaps you will like our other courses?',
  'offer.payment.successPage.btn1': 'View other courses',

  'offer.payment.failurePage.title': 'Payment Failure!',
  'offer.payment.failurePage.description.part1':
    'An error occurred during payment, carefully check your payment details and try again',
  'offer.payment.failurePage.description.part2':
    'If you are sure that your data is correct, please contact support',

  // END Offers

  // plan component
  'plan.h2': '¿Cómo es el proceso de los cursos?',
  'plan.1.h3': 'Aprende sobre el tema',
  'plan.1.description':
    'El curso consiste en lecciones en video detalladas y muy fáciles de entender',
  'plan.2.h3': 'Pon tu aprendizaje en práctica',
  'plan.2.description':
    'Cuentas con actividades para practicar a tu propio ritmo',
  'plan.3.h3': 'Intercambia conocimientos con el tutor',
  'plan.3.description':
    'Recibes corrección y retroalimentación personalizada sobre tus trabajos',
  'plan.4.h3': 'Crea un proyecto final propio',
  'plan.4.description':
    'Conocimientos que te ayudarán a desarrollar un proyecto individual para enriquecer tu portafolio',

  // about-us component
  'about-us.description':
    'La Escuela Británica de Artes Creativas y Tecnología (EBAC) es una institución innovadora de educación superior en Artes Creativas y Tecnología que ofrece cursos y programas de especialización en línea.',
  'about-us.1.title': '+45',
  'about-us.1.description':
    'cursos profesionales en el área de IT, diseño, marketing, audiovisual, fashion y negocios',
  'about-us.2.title': '145 mil',
  'about-us.2.description': 'alumnos estudiando nuestros cursos online',
  'about-us.3.title': '100%',
  'about-us.3.description':
    'de los profesores y coordinadores son profesionales en el mercado laboral',
  'about-us.4.title': '+40',
  'about-us.4.description':
    'empresas colaboran en el desarrollo de cursos, proyectos reales y programas de prácticas',

  // 'formText.part1': 'Al hacer clic en enviar aceptas los términos y condiciones de la',
  // 'formText.part2': 'Política de privacidad',
  'formText.part1': 'Confirmo que he leído, acepto y entiendo los',
  'formText.part2': 'términos y condiciones,',
  'formText.part3': 'así como el',
  'formText.part4': 'aviso de privacidad.',

  // sale page
  'sale.h1': 'Nós te amamos muito mesmo!',
  'sale.subtitle.part1': 'Desconto de 50%',
  'sale.subtitle.part2':
    'em todos os cursos online. Tá na hora de dar aquele salto na carreira e conquistar seu diferencial no mercado de trabalho. Garanta já seu curso na EBAC Promo Week!',
  'sale.endOfPromotion': 'La Oferta termina en',
  'sale.filter': 'Filtro',
  'sale.off': 'OFF',
  'sale.noInterestOnCardPart1': 'pagos de',
  'sale.noInterestOnCardPart2': 'sin intereses \n con tarjeta de crédito',
  'sale.installmentInUpToPart1':
    'A 12 meses sin intereses con tarjeta de crédito',
  'sale.installmentInUpToPart2': 'veces',
  'sale.installmentInUpToPart3': 'en la tarjeta',
  'sale.selectionHelp': '¿Necesitas ayuda?',
  'sale.placeAnOrder': 'Comprar',
  'sale.discountedAmount': 'Precio con descuento',
  'sale.content.section.professions.title': 'Profesiones',
  'sale.content.section.courses.title': 'Cursos',
  'sale.product.add': 'Comprar',
  'sale.product.soldOut': 'Agotado',
  'sale.product.remove': 'Eliminar',
  'sale.product.moreDetails': 'Conoce el curso',
  'sale.product.getCourse': 'Obtener curso',
  'sale.product.requestInProgress':
    'Su solicitud de apertura de curso está en curso. Recibirás un correo electrónico tan pronto como abramos un curso para ti.',
  'sale.product.study': 'Estudiar',
  'sale.content.more': 'Ver más',
  'sale.h2': 'Te ayudamos a escoger el mejor curso para ti',
  'sale.description':
    '¿Te gustaría tener más información sobre los cursos y decidir cuál es el que mejor se adapta a tus necesidades? Llena el formulario y uno de nuestros asesores se pondrá en contacto contigo.',
  'sale.name.label': 'Nombre',
  'sale.name.error': 'Escribe tu nombre',
  'sale.email.label': 'Email',
  'sale.email.error': 'Escribe tu e-mail',
  'sale.promocode.label': 'Tengo código promocional',
  'sale.promocode.error': 'Promocode error message',
  'sale.privacy-policy.part1':
    'Al hacer clic en enviar, aceptas los términos y condiciones de la',
  'sale.privacy-policy.part2': 'Política de Privacidad',
  'sale.btn': 'Enviar formulario',
  'sale.detailProductDialog.courseProgram': 'Contenido del Curso',
  'sale.detailProductDialog.professionProgram': 'Contenido de la Profesión',
  'sale.detailProductDialog.skillsBlockTitle': 'Qué aprenderás',
  'sale.downloadCourseProgram':
    'Descubre el programa del curso para obtener más información',
  'sale.trailer': 'Presentación del Curso',
  'sale.detailProductDialog.teachers': 'Profesores',
  'sale.detailProductDialog.footer.addButton': 'Agregar al carrito de compra',
  'sale.detailProductDialog.footer.addedButton': 'Añadido. Ir a la cesta',
  'sale.detailProductDialog.footer.closeButton': 'Ver otros cursos',
  'sale.basketDialogContent.title': 'Tu compra',
  'sale.basketDialogContent.total': 'Total',
  'sale.basketDialogContent.buttonLabel': 'Continuar',
  'sale.basketDialogContent.promocodeButtonLabel': 'Aplicar',
  'sale.basketDialogContent.discountedValue': 'Precio total con descuento',
  'sale.basketDialogContent.promocodeValue': 'Precio con código promocional',
  'sale.basketDialogContent.promocode.valid':
    'Código de promoción aplicado con éxito',
  'sale.basketDialogContent.promocode.exception':
    'Se ha producido un error, póngase en contacto con info@ebac.mx',
  'sale.basketDialogContent.promocode.couponNotFound':
    'No se encontró el cupón',
  'sale.basketDialogContent.promocode.couponUsed':
    'Se ha agotado el límite para utilizar este cupón',
  'sale.basketDialogContent.promocode.couponCannotBeUsedForProduct':
    'Este cupón no es válido para el curso',
  'sale.basketDialogContent.promocode.couponIsNotActive':
    'Este cupón no está activo',
  'sale.basketDialogContent.promocode.couponDidNotStart':
    'Este cupón no es válido',
  'sale.basketDialogContent.promocode.couponExpired':
    'Este cupón no puede utilizarse porque su validez ha expirado',
  'sale.basketDialogContent.promocode.couponCannotBeUsedForNomenclature':
    'Este cupón no puede utilizarse porque su validez ha expirado',
  'sale.basketDialogContent.promocode.smallDiscount':
    'El cupón aplicado tiene un descuento menor que el curso actual',
  'sale.basketDialogContent.promocode.discount':
    'Código de promoción aplicado con éxito',
  'sale.basketDialogContent.promocode.discountAmount':
    'Código de promoción aplicado con éxito',
  'sale.basketDialogContent.promocode.freeCourse':
    'Código de promoción aplicado con éxito',
  'sale.basketDialogContent.installmentPlansUpTo': 'A 12 meses sin intereses',
  'sale.basketDialogContent.installmentPlansUpTo.description.part1': '12 pagos',
  'sale.basketDialogContent.installmentPlansUpTo.description.part2':
    'con tarjeta',
  'sale.thx.thx': '¡Gracias!',
  'sale.thx.description.part1':
    'Gracias por inscribirte en EBAC. Pronto recibirás un correo e-mail con más información',
  'sale.thx.description.part2':
    'Ah, asegúrate de revisar tu carpeta de SPAM. Si quieres completar tu inscripción ahora y garantizar tu descuento, puedes optar por pagar en línea. El link aparecerá en un momento.',
  'sale.thx.description.part3': 'Atención: esta promoción es válida hasta el',
  'sale.thx.description.part4': 'a las',
  'sale.thx.loading': 'Cargando...',
  'sale.thx.endEnrollment': 'FINALIZAR INSCRIPCIÓN',
  'sale.free': 'Gratis',
  'sale.companyCatalogDialogContent.title': 'Atención',
  'sale.companyCatalogDialogContent.content': 'Debe estar autorizado en LMS',
  'sale.companyCatalogDialogContent.btn': 'Ok',
  'sale.badgeFirst': 'Descuentos exclusivos',
  'sale.badgeSecond.part1': 'Hasta',
  'sale.badgeSecond.part2': '60%',

  // saleBlock component
  'saleBlock.mobileHeadingPrefix': '',
  'saleBlock.mobileHeading': '2х1 en todos',
  'saleBlock.mobileHeadingPostfix': '',
  'saleBlock.mobileHeading.percentAdditional': '',
  'saleBlock.desktopHeadingPrefix': '',
  'saleBlock.desktopHeading': '¡Duplica tus oportunidades con EBAC!',
  'saleBlock.desktopHeadingPostfix': '',
  'saleBlock.desktopSubHeading': '',
  'saleBlock.discountTitle': '2x1',
  'saleBlock.button.label': 'ESCOGE TU CURSO',
  'saleBlock.mobilePercent': ' los cursos online',
  'saleBlock.desktopPercent': 'en todos\n los cursos online',

  'saleBlock.eternal.mobileHeadingPrefix': 'EBAC to school',
  'saleBlock.eternal.mobileHeading': '',
  'saleBlock.eternal.mobileHeadingPostfix': '',
  'saleBlock.eternal.desktopHeadingPrefix': '',
  'saleBlock.eternal.desktopHeading': 'EBAC to school',
  'saleBlock.eternal.desktopHeadingPostfix': '',
  'saleBlock.eternal.desktopSubHeading': '',
  'saleBlock.eternal.discountTitle': 'Descuento hasta',
  'saleBlock.eternal.button.label': 'ESCOGE TU CURSO',
  'saleBlock.eternal.mobilePercent': '',
  'saleBlock.eternal.desktopPercent': '-60%',

  'saleBlock.landing.button.label': 'INSCRÍBETE',

  //Contact Information
  'contactInformation.title': 'Información del contacto',
  'contactInformation.titlePersonal': 'Personal',
  'contactInformation.titleContact': 'Contacto',
  'contactInformation.titleAddress': 'Dirección',
  'contactInformation.fullName.label': 'Nombre compeleto',
  'contactInformation.fullName.error': 'Ingresa tu nombre completo',
  'contactInformation.identification.label': 'CURP o RFC',
  'contactInformation.identification.error': 'Ingresa tu CURP o RFC',
  'contactInformation.data.label': 'Fecha de nacimiento',
  'contactInformation.data.error': 'Ingresa tu fecha de nacimineto',
  'contactInformation.email.label': 'Email',
  'contactInformation.email.error': 'Ingresa tu E-mail',
  'contactInformation.phone.label': 'Teléfono',
  'contactInformation.confirmEmail.label': 'Confirma tu E-mail',
  'contactInformation.confirmEmail.error': 'Ingresa tu E-mail',
  'contactInformation.confirmEmail.confirmError':
    'Ingresa y confirma tu E-mail',
  'contactInformation.zipCode.label': 'Código Postal',
  'contactInformation.zipCode.error': 'Ingresa tu código postal',
  'contactInformation.address.label': 'Domicilio',
  'contactInformation.address.error': 'Ingresa tu domicilio',
  'contactInformation.country.label': 'País',
  'contactInformation.country.error': 'Ingresa tu país',
  'contactInformation.number.label': 'Número exterior',
  'contactInformation.number.error': 'Ingresa tu número exterior',
  'contactInformation.complement.label': 'Apartamento',
  'contactInformation.complement.error': 'Ingresa tu número de apartamento',
  'contactInformation.city.label': 'Ciudad',
  'contactInformation.city.error': 'Ingresa tu ciudad',
  'contactInformation.state.label': 'Estado',
  'contactInformation.state.error': 'Ingresa tu Estado',
  'contactInformation.neighborhood.label': 'Colonia',
  'contactInformation.neighborhood.error': 'Ingresa tu barrio o municipio',
  'contactInformation.cpf.error': 'Ingresa tu barrio o municipio',
  'contactInformation.part1':
    'Confirmo que he leído, acepto y entiendo los términos y condiciones, ',
  'contactInformation.part2': 'así como el aviso de privacidad',
  'contactInformation.btn': 'Enviar',
  'contactInformation.loan-request-dialog.success.title': '¡Excelente!',
  'contactInformation.loan-request-dialog.pending.title':
    '¡Ya llenaste este formulario!',
  'contactInformation.loan-request-dialog.pending.description':
    'Tu solicitud está siendo revisada por el administrador.',
  'contactInformation.loan-request-dialog.error.title': '¡Muchas gracias!',
  'contactInformation.loan-request-dialog.error.description':
    '¡Solucionaremos este problema a la brevedad posible! Por favor, inténtalo de nuevo más tarde.',
  'contactInformation.loan-request-dialog.btn': 'Ok',

  // Loan-request
  'loanRequest.info.title': 'Solicitud de préstamo',
  'loanRequest.info.total': 'Total',
  'loanRequest.info.instalments': 'Cuotas',
  'loanRequest.info.pagamento': 'Pagos',
  'loanRequest.needHelp': '¿Necesitas ayuda? Envíanos un E-mail',

  'loanRequest.thankyou.title': '¡Gracias!',
  'loanRequest.thankyou.description1':
    '¡Pagos programados conectados con éxito!',
  'loanRequest.thankyou.description2': '',

  'loanRequest.fail.title': '¡Algo parece haber salido mal!',
  'loanRequest.fail.description1':
    '¡Los pagos diferidos no se pueden procesar!',
  'loanRequest.fail.description2':
    'Póngase en contacto con su gerente o envíenos un e-mail a <a href="mailto:suporte@ebaconline.com.br" class="email">suporte@ebaconline.com.br</a>',

  'loanRequest.pay.flywire': 'Paga con FlyWire',
  'loanRequest.paid.flywire': 'Pagado',
  'loanRequest.canceled.flywire': 'Oferta cancelado',

  'loanRequest.instalment.expected': 'Esperado',
  'loanRequest.instalment.paid': 'Pagado',

  'webinar.directions.all.eventMetaDescription':
    'Los webinars te ayudarán a desarrollar habilidades en diseño, programación y marketing. Regístrate para ver transmisiones anteriores.',
  'webinar.directions.all.eventMetaTitle':
    'Webinars gratuitos y eventos EBAC Online',
  'webinar.directions.all.eventTitle': 'Webinars Gratuitos',

  'webinar.search.notFound': 'No encontramos resultados para tu búsqueda',
  'webinar.search.more': 'Más eventos',
  'webinar.type.discussion': 'Consejos profesionales',
  'webinar.type.success_case': 'Casos de éxito',
  'webinar.type.webinar': 'Webinar',
  'webinar.type.workshop': 'Workshop',
  'webinar.type.marathon': 'Maratón',
  'webinar.type.demonstration': 'Demostración',
  'webinar.type.event': 'Evento',
  'webinar.type.masterclass': 'Clase magistral',
  'webinar.type.immersion': 'Imersivo',

  'webinar.chat.title': 'Chat',
  'webinar.chat.register': 'Únete al debate',
  'webinar.chat.close': 'Ocultar chat',
  'webinar.chat.open': 'Abrir chat',

  'webinar.schedule.title': 'Programación por día',
  'webinar.speaker.title': 'Ponente',

  'webinar.topic.status.upcoming': 'Próximamente',
  'webinar.topic.status.live': 'En vivo',
  'webinar.topic.status.passed': 'Transmitido',

  'webinar.preRegister.title':
    'Para acceder al&nbsp;evento, utiliza el&nbsp;link que recibiste por mail despu&eacute;s de&nbsp;registrarte',
  'webinar.preRegister.description':
    'Si no te has registrado, ¡ahora es cuando!',
  'webinar.preRegister.submit': 'Regístrate ahora',

  'webinar.register.title':
    'Completa tus datos.<br/>Te&nbsp;enviaremos un&nbsp;link para acceder y&nbsp;recordatorios por email',
  'webinar.register.label.name': 'Nombre',
  'webinar.register.error.name': 'Escribe tu nombre',
  'webinar.register.label.email': 'Email',
  'webinar.register.error.email': 'Escribe tu email',
  'webinar.register.submit': 'REGISTRARME GRATIS',

  'webinar.registered': 'Ya estás registrado',

  'webinar.register.thanks.title': '¡Gracias por registrarte!',
  'webinar.register.thanks.text':
    'Recibir&aacute;s el&nbsp;email de&nbsp;confirmaci&oacute;n en&nbsp;los pr&oacute;ximos diez minutos, con el&nbsp;link para acceder al&nbsp;webinar. Para acceder al&nbsp;webinar, puedes usar el&nbsp;link que recibiste por email despu&eacute;s de&nbsp;registrarte. Si&nbsp;no&nbsp;has recibido el&nbsp;email de&nbsp;confirmaci&oacute;n, verifica en&nbsp;la&nbsp;carpeta de&nbsp;spam.',
  'webinar.register.thanks.ok': 'OK',

  'webinar.opinion.title': '¡Danos tu opinión!',
  'webinar.opinion.label.text':
    'Envía tus comentarios sobre el webinar y ayúdanos a mejorar',
  'webinar.opinion.error.text': 'Escribe tu comentario',
  'webinar.opinion.label.email': 'Email de contacto',
  'webinar.opinion.error.email': 'Escribe tu email',
  'webinar.opinion.privacy':
    'Al hacer clic en enviar, aceptas los términos y condiciones de',
  'webinar.opinion.privacy.link': 'Política de privacidad',

  'webinar.opinion.submit': 'Enviar',
  'webinar.other.title': 'Otros webinars',

  'webinar.promo.register.title': 'Regístrate para participar en el webinar',
  'webinar.promo.register.submit': 'Regístrate ahora',
  'webinar.promo.discount': 'Aprovecharon el descuento',

  'webinar.recommends': 'Recomendados',

  'webinar.filter.status.all': 'Todos',
  'webinar.filter.status.upcoming': 'Próximamente',
  'webinar.filter.status.finished': 'Anteriores',

  // Blog
  'blog.directions.all.metaDescription':
    'En el Blog de EBAC encontrarás contenido para profesionales y principiantes sobre diseño, marketing, softwares, programación & data, audiovisuales y gaming. Inspírate y mantente al día con todo lo que pasa en el sector.',
  'blog.directions.all.metaTitle': 'EBAC | Blog',
  'blog.directions.all.seoText':
    'En el Blog de EBAC encontrarás contenido para profesionales y principiantes sobre diseño, marketing, softwares, programación & data, audiovisuales y gaming. Inspírate y mantente al día con todo lo que pasa en el sector.',
  'blog.directions.all.title': 'Blog',

  // Page 404
  'page404.title': '¡Ups, página no encontrada!',
  'page404.titleMobile': '¡Ups,{0}página{0}no encontrada!',
  'page404.subtitle':
    'Parece que esta página no existe o ya fue eliminada.\n\nEstas opciones pueden ayudarte a encontrar lo que buscas:',

  'page404.links.home': 'Volver al inicio   →',
  'page404.links.courses': 'Cursos',
  'page404.links.professions': 'Profesiones',
  'page404.links.jobGuarantee': 'Cursos con garantía de empleo',
  'page404.links.upcomingEvents': 'Webinars',
  'page404.links.blogArticles': 'Blog ',
  'page404.links.aboutUs': 'Sobre EBAC',

  // aug 2022 redesign
  'rt0822.header.courses': 'Cursos',
  'rt0822.header.company': 'EBAC',
  'rt0822.header.events': 'Webinars',
  'rt0822.header.blog': 'Blog',
  'rt0822.header.toPartners': 'Para empresas',
  'rt0822.header.toPartners.corporateEducation': 'Educación Corporativa',
  'rt0822.header.toPartners.challenges': 'Atracción de Talento',
  'rt0822.header.login': 'Login',

  'rt0822.main.titleGradient': 'EBAC es\xa0el\xa0nuevo impulso',
  'rt0822.main.title': 'para tu\xa0carrera',
  'rt0822.main.subtitle':
    'Somos una escuela online que te\xa0enseña a\xa0conquistar las profesiones más demandadas en\xa0el\xa0mercado laboral',
  'rt0822.main.allCourses': 'Ver los cursos\xa0\xa0\xa0\xa0→',

  'rt0822.footer.directions': 'Cursos',
  'rt0822.footer.projects': 'Proyectos',
  'rt0822.footer.blog': 'Blog',
  'rt0822.footer.webinars': 'Webinars',
  'rt0822.footer.about': 'Sobre',
  'rt0822.footer.aboutCompany': 'EBAC',
  'rt0822.footer.careerCenter': 'Centro de Carreras',
  'rt0822.footer.vacancies': 'Vacantes',
  'rt0822.footer.toPartners': 'Para empresas',
  'rt0822.footer.toCorporateClients': 'Educação corporativa',
  'rt0822.footer.toCorporateClients.url': '/para-empresas/corporate-education',
  'rt0822.footer.suggestVacancy': 'Atracción de Talento',
  'rt0822.footer.suggestVacancy.url': '/para-empresas/challenges',
  'rt0822.footer.whatsapp': 'WhatsApp',
  'rt0822.footer.telephone': 'Teléfono',
  'rt0822.footer.address': 'EBAC EDUCACIÓN, S.A. DE C.V.',
  'rt0822.footer.entity':
    'Av. Circunvalación Oriente 8 Int.104, Ciudad Satélite, Naucalpan de Juarez, CP 53100 Estado de México',

  'footer.directions': 'Cursos',
  'footer.projects': 'Proyectos',
  'footer.about': 'Sobre',
  'footer.webinars': 'Webinars',
  'footer.blog': 'Blog',
  'footer.corporateEducation': 'Educación Corporativa',
  'footer.challenges': 'Atracción de Talento',

  'rt0822.jobGuaranteePolicy.title': 'Política de\xa0Emprego Garantido',
  'rt0822.jobGuaranteePolicy.text':
    'Garantimos emprego após a\xa0conclusão de\xa0determinados cursos profissionalizantes ou\xa0o\xa0seu dinheiro de\xa0volta.\n\nA Política de\xa0Emprego Garantido exige dedicação. Será preciso esforço e\xa0foco no\xa0cumprimento integral de\xa0todos os\xa0requisitos do\xa0programa.\n\nAcesse a\xa0lista dos cursos com garantia de\xa0emprego.',
  'rt0822.jobGuaranteePolicy.button': 'Leia os\xa0Termos do\xa0Programa',
  'rt0822.jobGuaranteePolicy.url':
    '/upload/cms/programa_de_empregabilidade.pdf',

  'rt0822.coursesAndProfessions.companyText':
    'Empieza de\xa0cero o\xa0especialízate',
  'rt0822.coursesAndProfessions.text':
    'Nuestro objetivo es\xa0mostrarte el\xa0camino para conseguir el\xa0trabajo de\xa0tus sueños.\nTe\xa0enseñamos a\xa0desarrollar habilidades prácticas que te\xa0ayudarán a\xa0empezar una carrera nueva o\xa0ascender en\xa0la\xa0misma empresa.',
  'rt0822.coursesAndProfessions.courses.title': 'Cursos',
  'rt0822.coursesAndProfessions.courses.text':
    'Profundiza tus conocimientos en\xa0un\xa0área específica o\xa0aprende a\xa0utilizar diferentes herramientas a\xa0un\xa0nivel avanzado. Recomendado si\xa0eres principiante y\xa0quieres ser un\xa0futuro experto.',
  'rt0822.coursesAndProfessions.courses.button':
    'Escoge entre {coursesCount}\xa0Cursos',
  'rt0822.coursesAndProfessions.professions.title': 'Profesiones',
  'rt0822.coursesAndProfessions.professions.text':
    'Prepárate para empezar un\xa0trabajo completamente nuevo. Ideal si\xa0quieres cambiar tu\xa0vida, pero aún careces de\xa0habilidades en\xa0el\xa0área deseada.',
  'rt0822.coursesAndProfessions.professions.button':
    'Escoge entre {professionsCount}\xa0Profesiones',

  'rt0822.bestCourse.subtitle': 'Curso campeão de vendas',
  'rt0822.bestCourse.title': 'Comece em TI com emprego garantido',
  'rt0822.bestCourse.description':
    'O curso mais popular, onde você vai se aprofundar em 5 profissões e então será capaz de escolher aquela que gostou mais',
  'rt0822.bestCourse.button': 'Curso de TI online para iniciantes',

  'rt0822.about.fact1.number': '145 mil',
  'rt0822.about.fact1.text': 'alumnos estudiando con nosotros',
  'rt0822.about.fact2.number': '78%',
  'rt0822.about.fact2.text':
    'de\xa0nuestros alumnos encuentran trabajos relevantes al\xa0finalizar nuestros cursos',
  'rt0822.about.fact3.number': '40',
  'rt0822.about.fact3.text':
    'empresas nos ayudan a\xa0desarrollar nuestros programas de\xa0estudio en\xa0base a\xa0las necesidades del mercado laboral',

  'rt0822.professors.title': 'Aprende con los mejores expertos del mercado',
  'rt0822.professors.text':
    'Invitamos a\xa0profesionales top de\xa0empresas reconocidas a\xa0participar en\xa0la\xa0creación de\xa0nuestros cursos, de\xa0esta forma nos aseguramos de\xa0enseñar lo\xa0que el\xa0mercado necesita.',
  'rt0822.professors.name.1': 'Fernanda Rionda',
  'rt0822.professors.text.1':
    'Fundadora de\xa0<span class="professors__professor-text--bold">Jardín Sustentable</span> y\xa0<span class="professors__professor-text--bold">Garden Lab</span>',
  'rt0822.professors.name.2': 'Carlos H\xa0Fernández',
  'rt0822.professors.text.2':
    'Arquitecto fundador de\xa0<span class="professors__professor-text--bold">Atlas de\xa0Arquitectura</span> y\xa0cofundador de\xa0<span class="professors__professor-text--bold">Bestia Estudio</span>',
  'rt0822.professors.name.3': 'Francisco Cancino',
  'rt0822.professors.text.3': 'Director creativo y\xa0Diseñador de\xa0moda',
  'rt0822.professors.name.4': 'Luis Ribó',
  'rt0822.professors.text.4': 'Redactor creativo',
  'rt0822.professors.name.5': 'Rosina Marisin',
  'rt0822.professors.text.5': 'Diseñadora gráfica e\xa0ilustradora',
  'rt0822.professors.name.6': 'Josué Manuel López Lezama',
  'rt0822.professors.text.6':
    'Frontend Engineer en\xa0<span class="professors__professor-text--bold">Cornershop</span> by\xa0<span class="professors__professor-text--bold">Uber</span>',
  'rt0822.professors.name.7': 'Rodrigo Torrubia',
  'rt0822.professors.text.7':
    'Head de\xa0Community Management en\xa0<span class="professors__professor-text--bold">Element</span>',
  'rt0822.professors.name.8': 'Andrés Lara',
  'rt0822.professors.text.8':
    'Teaching Assistant en\xa0<span class="professors__professor-text--bold">Lost Boys Studios</span>',
  'rt0822.professors.name.9': 'Emiliano González',
  'rt0822.professors.text.9':
    'Consultor en\xa0tendencias y\xa0Fashion Marketing',
  'rt0822.professors.name.10': 'José Raúl Castro Esparza',
  'rt0822.professors.text.10':
    'Data Scientist en\xa0<span class="professors__professor-text--bold">Palisade</span>',
  'rt0822.professors.name.11': 'Gustavo Vinueza C.',
  'rt0822.professors.text.11':
    'Head de Analytics en\xa0<span class="professors__professor-text--bold">Betterfly</span>',
  'rt0822.professors.name.12': 'Salvador Perez',
  'rt0822.professors.text.12':
    'Java Developer en\xa0<span class="professors__professor-text--bold">Bitso</span>',

  'rt0822.countries.title': 'Enseñamos en\xa0todo el\xa0mundo',
  'rt0822.countries.text':
    'Tienes la\xa0opción de\xa0estudiar en\xa0español o\xa0portugués.',

  'rt0822.countries.brasil.title': 'Brasil',
  'rt0822.countries.brasil.text':
    'Funcionando desde <b>agosto del 2020</b>.\n\nImparten más de\xa0<b>150\xa0cursos</b> de\xa0Programación &\xa0Data, Marketing, Audiovisual, Diseño, Gaming, Fashion y\xa0Software.',

  'rt0822.countries.méxico.title': 'México',
  'rt0822.countries.méxico.text':
    'Funcionando desde <b>agosto del 2021</b>.\n\nImparten más de\xa0<b>43\xa0cursos</b> de\xa0Programación &\xa0Data, Marketing, Diseño, Gaming, Fashion y\xa0Software.',

  'rt0822.countries.colômbia.title': 'Colômbia',
  'rt0822.countries.colômbia.text':
    'Funcionando desde <b>marzo del 2022</b>.\n\nImparten más de\xa0<b>43\xa0cursos</b> de\xa0Programación &\xa0Data, Marketing, Diseño, Gaming y\xa0Software.',

  'rt0822.countries.peru.title': 'Peru',
  'rt0822.countries.peru.text':
    'Funcionando desde <b>marzo del 2022</b>.\n\nImparten más de\xa0<b>43\xa0cursos</b> de\xa0Programación &\xa0Data, Marketing, Diseño, Gaming y\xa0Software.',

  'rt0822.partners.title': 'Nuestros socios',
  'rt0822.partners.text':
    'Empresas reconocidas del mercado comparten su\xa0experiencia y\xa0participan en\xa0la\xa0creación de\xa0nuestros cursos, además de\xa0contratar a\xa0muchos de\xa0nuestros mejores estudiantes.',

  'rt0822.studio.title':
    'Te\xa0garantizamos la\xa0mejor experiencia de\xa0aprendizaje',
  'rt0822.studio.text':
    'Todos nuestros cursos están creados en\xa0base a\xa0una investigación detallada del mercado laboral. Primero, descubrimos las habilidades más demandadas y\xa0luego diseñamos el\xa0curso para garantizar que nuestros alumnos estén a\xa0la\xa0altura de\xa0estos requisitos.',

  'rt0822.studio.slide1.text':
    'Nuestros profesores y\xa0tutores son expertos en\xa0el\xa0campo que enseñan. Así, garantizamos que la\xa0información y\xa0los procesos sean los más actuales del mercado.',
  'rt0822.studio.slide2.text':
    'Las actividades y\xa0los proyectos finales son parte esencial de\xa0todos nuestros cursos, por eso contamos con tutores altamente capacitados para brindarte feedback  personalizado y\xa0obtener habilidades prácticas, además de\xa0conocimiento teórico.',
  'rt0822.studio.slide3.text':
    'Facilitamos tu\xa0aprendizaje con cursos grabados en\xa0el\xa0estudio, diferentes cámaras, luces y\xa0escenarios para hacer tu\xa0experiencia más real y\xa0dinámica.',
  'rt0822.studio.slide4.text':
    'Todas las actividades están basadas en\xa0proyectos profesionales, lo\xa0que ayudará a\xa0que tu\xa0currículum y\xa0portafolio destaquen.',
  'rt0822.studio.slide5.text':
    'Los consultores de\xa0nuestro Centro de\xa0Carreras están preparados para guiarte a\xa0través del proceso de\xa0contratación. También te\xa0orientarán para completar tu\xa0perfil de\xa0LinkedIn, crear un\xa0portafolio atractivo o\xa0prepararte para tus futuras entrevistas de\xa0trabajo.',

  'rt0822.webinars.title':
    'Descubre tu\xa0próxima profesión en\xa0nuestros webinars gratuitos ',
  'rt0822.webinars.text':
    'Si\xa0no\xa0sabes qué profesión elegir, te\xa0invitamos ver webinars anteriores o\xa0registrarte en\xa0los próximos workshops o\xa0maratones gratuitos, que te\xa0ayudarán a\xa0familiarizarte con cada área y\xa0harás algunos ejercicios prácticos que podrás agregar a\xa0tu\xa0portafolio.\n\nLos mejores participantes obtienen descuentos y\xa0regalos.',
  'rt0822.webinars.button': 'Ver webinars',

  'rt0822.webinars.type.discussion': 'Dicas de\xa0carreira',
  'rt0822.webinars.type.success_case': 'Cases de\xa0sucesso',
  'rt0822.webinars.type.webinar': 'Webinar',
  'rt0822.webinars.type.workshop': 'Workshop',
  'rt0822.webinars.type.marathon': 'Maratona',
  'rt0822.webinars.type.demonstration': 'Demonstração',
  'rt0822.webinars.type.event': 'Evento',
  'rt0822.webinars.type.masterclass': 'Masterclass',
  'rt0822.webinars.type.immersion': 'Imersão',

  'rt0822.reviewVideos.title': 'Qué dicen nuestros alumnos',

  'rt0822.popular.title': 'Cursos más populares',
  'rt0822.popular.text':
    'Descubre los cursos preferidos entre nuestros alumnos',
  'rt0822.popular.button': 'Ver más',

  'rt0822.popular.course.professionInteriorDesigner':
    'Profesión Diseño y\xa0decoración de\xa0Interiores',
  'rt0822.popular.course.professionGraphicDesigner':
    'Profesión Diseñador Gráfico',
  'rt0822.popular.course.professionDataAnalyst':
    'Profesión Analista de\xa0Datos',
  'rt0822.popular.course.professionUXUIDesigner': 'Profesión Diseñador UX/UI',
  'rt0822.popular.course.professionFullStackPythonDeveloper':
    'Profesión Desarrollador Full Stack Python',
  'rt0822.popular.course.professionSocialMediaManager':
    'Profissão Social Media Manager',
  'rt0822.popular.course.coursePersonalStylist': 'Personal Stylist',
  'rt0822.popular.course.professionPerformanceMarketingManager':
    'Profissão Marketing de\xa0Performance',
  'rt0822.popular.course.courseCopywriting': 'Copywriting',
  'rt0822.popular.course.professionDataScientist':
    'Profissão Cientista de\xa0Dados',

  'rt0822.popular.course.paisajismo': 'Paisajismo',
  'rt0822.popular.course.illustrationDigital': 'Ilustración Digital',
  'rt0822.popular.course.fashionMarketing': 'Fashion Marketing',
  'rt0822.popular.course.sqlAnalysis': 'SQL para análisis de\xa0datos',

  'rt0822.popular.category.design': 'Design',
  'rt0822.popular.category.fashion': 'Fashion',
  'rt0822.popular.category.marketing': 'Marketing',
  'rt0822.popular.category.programming': 'Programação & Data',

  'rt0822.popular.duration': '{duration} meses',

  'rt0822.form.title.1': '¿Necesitas ayuda?',
  'rt0822.form.title.2': '',
  'rt0822.form.text':
    'Escríbenos, estaremos encantados de\xa0ayudarte a\xa0elegir el\xa0curso que más te\xa0convenga, en\xa0función de\xa0tus intereses, nivel de\xa0conocimiento y\xa0objetivos profesionales.',

  'rt0822.form.placeholder.name': 'Nombre',
  'rt0822.form.placeholder.email': 'Email',
  'rt0822.form.sendButton': 'Enviar',

  'rt0822.form.success.title': '¡Gracias por tu\xa0solicitud!',
  'rt0822.form.success.text':
    'En\xa0breve, nos pondremos en\xa0contacto contigo.',
  'rt0822.form.success.button': 'Ok, fechar',

  'courses.form.success.title': '¡Gracias por tu\xa0solicitud!',
  'courses.form.success.text':
    'En\xa0breve, nos pondremos en\xa0contacto contigo.',
  'courses.form.success.button': 'Ok, fechar',

  'rt0822.blog.button': 'Ver blog',

  // EVENTOS
  'eventos.register.title': 'Registro',

  'eventos.register.type.title.online': 'Webinar',
  'eventos.register.type.title.online.mobile': 'Webinar',
  'eventos.register.type.title.offline': 'Offline event',
  'eventos.register.type.places': '{now} / {total} Quedan plazas libres',

  'eventos.register.form.name': 'Nombre',
  'eventos.register.form.email': 'Email',
  'eventos.register.form.submit': 'Regístrate ahora',
  'eventos.register.gift.text':
    'Únete al webinar ahora y recibe el tutorial  "Cómo potenciar tu perfil en Linkedin", de forma gratuita',

  'eventos.register.success.title': '¡Te has registrado con éxito!',
  'eventos.register.success.text':
    'Gracias, estamos confirmando tu\xa0participación en\xa0el\xa0webinar.',
  'eventos.register.success.button': 'Ok, fechar',

  'eventos.speakers.titleMultiple': 'Ponente',
  'eventos.speakers.title': 'Ponente',
  'eventos.speakers.more': 'Leer más',
  'eventos.speakers.less': 'Leer menos',

  'eventos.recomendations.title': 'Webinars interesantes',
  'eventos.recomendations.more': 'Mostrar más',

  'eventos.schedule.title': 'Agenda de webinars',

  'eventos.topics.day': 'Day',

  'eventos.stream.chat.title': 'Chat',

  'eventos.description.prefix': 'Participa en evento',
  'eventos.type.discussion': 'Tips profesionales',
  'eventos.type.success_case': 'Casos de éxito',
  'eventos.type.webinar': 'Webinar',
  'eventos.type.workshop': 'Workshop',
  'eventos.type.marathon': 'Maratona',
  'eventos.type.demonstration': 'Demostración',
  'eventos.type.event': 'Evento',
  'eventos.type.masterclass': 'Masterclass',
  'eventos.type.immersion': 'Inmersivo',

  'eventos.breadcrumbs.home': 'Home',
  'eventos.breadcrumbs.eventos': 'Webinars',

  'eventos.banner.members': 'Aproveitaram<br/>o desconto',
  'eventos.banner.discount': 'del descuento',

  'eventos.topic.num': 'DIA',

  'eventos.subscribed.title': '🔥 ¡Listo!',
  'eventos.subscribed.subtitle':
    'Recibirás un\xa0mail como recordatorio cuando empiece el\xa0webinar.',
  'eventos.subscribed.timer.title': 'Empezamos en:',
  'eventos.subscribed.timer.value': 'En breve',
  'eventos.subscribed.members': 'Participantes',
  'eventos.subscribed.button.default': 'Compartir con un amigo',
  'eventos.subscribed.button.clicked': 'Link copiado',
  'eventos.subscribed.button.info': 'Invita a un amigo enviándole el link',
  'eventos.register.error.email': 'Escribe tu email',
  // EVENTOS END

  // EVENTOS LIST START
  'rt0822.events.head.title': 'Webinars gratuitos y eventos EBAC Online',
  'rt0822.events.head.description':
    'Los webinars te ayudarán a desarrollar habilidades en diseño, programación y marketing. Regístrate para ver transmisiones anteriores.',

  'rt0822.multiselect.selected': '{count} seleccionada',

  'rt0822.multiselect.directions.selected':
    '{count} área seleccionada | {count} áreas seleccionadas',
  'rt0822.multiselect.types.selected':
    '{count} tipo de webinar | {count} tipos de webinar',

  'rt0822.events.filters.directions.placeholder': 'Todas las áreas',
  'rt0822.events.filters.types.placeholder': 'Todo tipo de webinars',

  'rt0822.events.filter.mobile.directions.title': 'Todas las áreas',
  'rt0822.events.filter.mobile.types.title': 'Todo tipo de webinars',

  'rt0822.events.filters.search.placeholder': 'Buscar webinars',
  'rt0822.events.filter.clear': 'Borrar filtro',

  'rt0822.events.more': 'Mostrar más',

  'rt0822.events.filter.mobileLabel':
    'Filtros | {count} seleccionado | {count} filtros seleccionados',

  'rt0822.events.filter.mobile.title': 'Filtros',
  'rt0822.events.filter.mobile.select': 'Seleccionar',
  'rt0822.events.filter.mobile.apply': 'Aplicar filtro',

  'rt0822.events.title': 'Webinars EBAC',
  'rt0822.events.past': 'Webinars Anteriores',
  'rt0822.events.upcoming': 'Próximos Webinars',
  'rt0822.events.subheading':
    'Si tienes duda sobre qué profesión elegir, te invitamos a participar en nuestros workshops o maratones gratuitos para  familiarizarte con el tema. También podrás hacer ejercicios prácticos y agregarlos a tu portafolio.',
  'rt0822.events.nothingWasFound':
    'No se encontró nada relacionado a tu solicitud.',
  // EVENTOS LIST END

  //PAYMENT PAGE
  'payment.generating': 'Estamos generando tu link de pago',
  'payment.privacy-1': 'He leído y acepto los',
  'payment.privacy-2': 'así como el',
  'payment.privacy-link-1': 'términos y condiciones',
  'payment.privacy-link-2': 'aviso de privacidad',
  'payment.thanks-for-waiting': '¡Gracias por la espera!',
  'payment.payment-link-is-ready': 'Tu link de pago está listo',
  'payment.final': 'Continuar con el pago',
  'payment.success': 'El pago se ha realizado con éxito',
  'payment.success-details':
    'Hemos enviado un mail con los detalles de tu compra y el acceso a la plataforma de aprendizaje',
  'payment.email': 'Si tienes dudas, escríbenos a',
  'payment.something-wrong': '¡Uy! Algo salió mal',
  'payment.something-wrong-dsc':
    'Hubo un erro en el pago. Es posible que hayas introducido datos incorrectos o que no dispongas de saldo suficiente para este pago',
  'payment.try-again': 'Volver a intentarlo',

  'saleNew.todos': 'Todos',
  'saleNew.card.discount.off': 'OFF',
  'saleNew.type.course': 'Cursos',
  'saleNew.type.profession': 'Profesiones',
  'saleNew.timerPanel.btn': '¿Buscas un consejo?',
  'saleNew.cards.title.profession.todos': 'Profesiones',
  'saleNew.cards.title.course.todos': 'Cursos',
  'saleNew.cards.title.course': 'Cursos',
  'saleNew.cards.title.profession': 'Profesiones',
  'saleNew.timerPanel.text-1': '¡Asegura',
  'saleNew.timerPanel.text-2': 'tu descuento!',
  'saleNew.additional.jobGuarantee': 'Garantía de empleo',
  'saleNew.price.value.noPercents':
    'a meses sin intereses<br/>con tarjeta de crédito',
  'saleNew.price.plan': 'pagos de',
  'saleNew.card.action.add': 'Comprar',
  'saleNew.card.action.open': 'Conoce el curso',
  'saleNew.cards.title.category': 'de',
  'saleNew.cards.title.profession.jobGuarantee': 'Garantía de empleo',
  'saleNew.tooltip.jobGuarantee.title': 'Garantía de empleo',
  'saleNew.tooltip.jobGuarantee':
    'En EBAC te garantizamos un trabajo al terminar de estudiar una de estas profesiones, y si no lo consigues, te regresamos tu dinero. Tu dedicación y esfuerzo son algunos puntos clave para conseguirlo. Encuentra los requisitos de la Garantía de Empleo <a href="https://ebac.mx/upload/cms/proyecto_de_empleabilidad.pdf" target="_blank">aquí</a>',
  'saleNew.tooltip.professions.title': 'Profesiones',
  'saleNew.tooltip.professions':
    'Este programa es para ti si quieres dominar habilidades específicas, crear un portafolio profesional y un currículum que te garanticen una oportunidad en el mercado laboral.',
  'saleNew.tooltip.courses.title': 'Cursos',
  'saleNew.tooltip.courses':
    'Programa a corto plazo que te permitirá ingresar a una especialización en el área elegida, aprendiendo habilidades específicas desde el nivel básico hasta el avanzado que utilizarás para tu portafolio y crecimiento profesional.',
  'saleNew.card.action.added': 'Continuar con el pago',

  // EventSale Redisign
  'eventSale.badge': 'Oferta exclusiva',

  'eventSale.message.title': 'Reserva tu consulta',
  'eventSale.message.subtitle.1': 'Y garantiza ',
  'eventSale.message.subtitle.2': 'de descuento',
  'eventSale.message.subtitle.3': 'en el curso',

  // Y garantiza X% de descuento en el curso YY pagando 12 x $ XXX.XX
  'eventSale.message.subtitle.2b': 'de descuento en el curso',
  'eventSale.message.subtitle.3b': '',

  'eventSale.message.subtitle.4': 'pagando 12 x ',

  'eventSale.form.title': '¿Cómo podemos contactarte?',
  'eventSale.form.submit': 'Enviar',

  'eventSale.timer.days': 'dias',
  'eventSale.timer.hours': 'horas',
  'eventSale.timer.minutes': 'minutos',
  'eventSale.timer.seconds': 'segundos',

  'eventSale.thanks.title': '¡Gracias!',
  'eventSale.thanks.subtitle':
    'Gracias por tu registro, nuestros consultores te contactarán en breve.',
  'eventSale.thanks.form.title': '¿Quieres finalizar tu inscripción ahora?',
  'eventSale.thanks.form.subtitle':
    'Haz tu pago online. Es fácil, rápido y seguro.',
  'eventSale.thanks.form.submit': 'Completar tu inscripción',

  // EventSale Redisign END
  moreButton: 'Mostrar mais',

  // Snackbar
  'snackbar.error.default':
    'Ocurrió un error. Por favor, inténtelo de nuevo más tarde.',
  // Snackbar END

  //New payment thankyou page (august 2024)
  'payment.title': '¡Gracias por registrarte!',
  'payment.description.desktop':
    'Nos pondremos en contacto contigo a la brevedad posible. <br/> Si quieres empezar hoy mismo tu curso, te recomendamos completar tu inscripción online. <br/> <br/> El pago online es seguro, fácil y rápido. Si no te gusta el curso al que te inscribiste, tienes una garantía de reembolso de 7 días. <br/> <br/> Contamos con otros metodos de pago y financiemiento. Si quieres conocerlos, uno de nuestros asesores se pondrá en contacto.',
  'payment.description.mobile':
    'Nos pondremos en contacto contigo a la brevedad posible. <br/> Si quieres empezar hoy mismo tu curso, te recomendamos completar tu inscripción online. <br/> <br/> El pago online es seguro, fácil y rápido. Si no te gusta el curso al que te inscribiste, tienes una garantía de reembolso de 7 días. <br/> <br/> Contamos con otros metodos de pago y financiemiento. Si quieres conocerlos, uno de nuestros asesores se pondrá en contacto.',
  'payment.help':
    '¿Tienes preguntas? Te ayudamos a resolver todas tus dudas. <br/> Contáctanos al número <b>+52 55 9225-2629</b>',
  'payment.help.mobile':
    '¿Tienes preguntas? Te ayudamos a resolver todas tus dudas. Contáctanos al número <b>+52 55 9225-2629</b>',
  'payment.order.summary.title': 'Tu descuento fue aplicado',
  'payment.order.discount.title': 'Descuento',
  'payment.order.amount.title': 'Precio total con descuento',
  'payment.order.choose.payment.method.title': 'Elige tu metodo de pago',
  'payment.thankyou.title': 'Gracias por tu compra',
  //WhatsApp Widget
  'whatsappWidget.bubble': '¿Alguna pregunta?',

  // Subscribe popup webinars
  'subscribePopup.webinars.title': '¿Quieres estudiar gratis con EBAC?',
  'subscribePopup.webinars.subscribe': '¡Suscríbete!',
  'subscribePopup.webinars.form.email': 'Email',
  'subscribePopup.webinars.form.submit': 'Suscribir',
  'subscribePopup.webinars.form.placeholder.name': 'Nombre',
  'subscribePopup.webinars.form.success.title': '¡Gracias por suscribirte!',
  'subscribePopup.webinars.form.success.description':
    'Prepárate para recibir las mejores noticias y oportunidades en tu correo',

  // Cookie policy page
  'cookiePolicy.title1': 'Cookies y Beacons',
  'cookiePolicy.description1':
    'EBAC  EDUCACIÓN,  S.A.  DE  C.V.,  en  adelante  “EBAC”  te  informa  que  este  sitio  web,  utiliza cookies,  web  Beacons  y  otras  tecnologías  a  través  de  las  cuales  es  posible  monitorear  el comportamiento  del  titular  como  usuario  de  Internet,  con  la  finalidad  de  brindar  un  mejor servicio y/o experiencia al navegar en el mismo.',
  'cookiePolicy.description2':
    'Los Datos Personales que se podrán obtener, de manera enunciativa más no limitativa, son: (i) datos  generales;  (ii)  datos  de  contacto;  (iii)  horario  y  tiempo  de  navegación;  (iv)  sitios  web  y secciones consultadas; (v) ubicación; de conformidad con las políticas de EBAC.',
};
