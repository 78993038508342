import * as Sentry from '@sentry/vue';

export async function getGRecaptchaScore() {
  try {
    if (process.server) return;

    const grecaptchaEnabled = this.$app.config.current.grecaptchaEnabled;
    if (!grecaptchaEnabled) return;

    const grecaptcha = window.grecaptcha;
    if (!grecaptcha) {
      console.error('GRecaptcha enabled but not loaded');
    }

    // Страна не учитывается на сервере
    // (используется configManager, который сам определяет площадку)
    // Но я отправляю на всякий случай
    const country = this.$app?.config?.common?.currentCountry || 'br';

    // Публичный ключ сайта устанавливается в конфиге.
    // Он разный для MX и BR площадок
    const grecaptchaPublicKey = this.$app.config.current.grecaptchaPublicKey;
    if (!grecaptchaPublicKey) return;

    // Уникальный токен. Живёт 2 минуты, так что его не нужно сохранять
    const token = await queryToken(grecaptcha, grecaptchaPublicKey);

    // Получить score юзера, отправив на сервер его токен
    // (рукопожатие)
    const response = await this.$axios({
      method: 'GET',
      url: `${this.$app.config.current.CMS_REST_API}/public/v2/grecaptcha`,
      params: {
        token,
        country,
      },
    });

    const data = response?.data?.data;

    if (data.success) {
      saveScoreToLocalStorage(data);
      setSentryGRecaptchaContextAndTag.bind(this)();

      return data.score;
    }
  } catch (e) {
    console.error(e);
  }
}

// Получить токен юзера, который будет отправлен на сервер
// для рукопожатия
async function queryToken(grecaptcha, grecaptchaPublicKey) {
  // Promisify for grecaptcha.ready
  function grecaptchaReady() {
    return new Promise((resolve, reject) => {
      try {
        grecaptcha.ready(() => {
          resolve();
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  await grecaptchaReady();

  return Promise.race([
    grecaptcha.execute(grecaptchaPublicKey, { action: 'submit' }),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('reCAPTCHA timeout')), 5000),
    ),
  ]);
}

// Сохранить score юзера в локальное хранилище
function saveScoreToLocalStorage(data) {
  const string = JSON.stringify({
    score: data.score,
    challenge_ts: data.challenge_ts,
  });
  window?.localStorage?.setItem('last_gr_score', string);
}

// Добавить score юзера в контекст Sentry
export function setSentryGRecaptchaContextAndTag(conf) {
  try {
    const config = this?.$app?.config?.current || conf?.current;
    if (!config) {
      return console.error(
        'config not provided in setSentryGRecaptchaContextAndTag function',
      );
    }

    const grecaptchaEnabled = config.grecaptchaEnabled;
    if (!grecaptchaEnabled) return;

    const stringScoreData = window?.localStorage?.getItem('last_gr_score');
    if (!stringScoreData) return;

    const parsedScoreData = JSON.parse(stringScoreData);
    if (!parsedScoreData) return;

    // Контекст видно в событии, но он недоступен для сортировки и поиска
    Sentry.setContext('grecaptcha', {
      score: parsedScoreData.score,
      challenge_ts: parsedScoreData.challenge_ts,
    });

    // В Sentry доступен поиск и фильтрация по тегам
    if (parsedScoreData.score) {
      Sentry.setTag('grecaptcha_score', parsedScoreData.score);
    }
  } catch (e) {
    console.error(e);
  }
}
