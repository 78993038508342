//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Checkbox from '../../../components/common/checkbox';
import config from '@/config/countries';

export default {
  name: 'LoginForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    Checkbox,
  },
  props: ['closeDialogFunc'],
  data() {
    return {
      email: '',
      password: '',
      rememberMe: true,
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      const currentConfig = config(this.$nuxt.$config.COUNTRY);
      const LMS_REST_API = currentConfig.current.LMS_REST_API;
      if (this.loading) return;

      this.loading = true;
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        this.loading = false;
        return;
      }

      try {
        const loginResponse = await this.$axios({
          method: 'post',
          url: `${LMS_REST_API}/auth/login`,
          data: {
            email: this.email,
            password: this.password,
          },
        });

        if (loginResponse.data && loginResponse.data.access_token) {
          const maxAge = this.rememberMe
            ? 60 * 60 * 24 * 365
            : 60 * 60 * 24 * 1;
          document.cookie = `a_token=${loginResponse.data.access_token}; path=/; domain=${window.location.hostname}; max-age=${maxAge}; secure`;

          const infoResponse = await this.$axios({
            method: 'get',
            url: `${LMS_REST_API}/auth/info`,
            headers: {
              Authorization: `Bearer ${loginResponse.data.access_token}`,
            },
          });

          if (infoResponse.data) {
            this.$app.user = infoResponse.data;
          }
        }

        this.closeDialogFunc();

        // this.$gtm.push({ 'event': 'autoEvent', 'eventCategory': 'click', 'eventAction': 'login', 'eventLabel': 'cursos_page' });
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    },
  },
};
