export default function ({ $axios }) {
  $axios.defaults.timeout = 30000; // 1000 * 30 ms

  $axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.isAxiosError) {
        return Promise.reject(error);
      }

      const url = error.config?.url || 'unknown URL';
      const method = error.config?.method?.toUpperCase() || 'UNKNOWN';

      let errorType = 'unknown';
      let errorMessage = '';

      if (error.code === 'ECONNABORTED') {
        errorType = 'timeout';
        errorMessage = `Request timeout (${error.config.timeout}ms)`;
      } else if (!error.response) {
        errorType = 'network';
        errorMessage = error.message || 'Network Error';
      } else if (error.response) {
        errorType = 'server';
        errorMessage = `Server Error ${error.response.status}`;
      }

      error.message = `[axios:${errorType}] ${errorMessage} for ${method} ${url}`;

      return Promise.reject(error);
    },
  );
}
