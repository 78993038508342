import config from '@/config/countries';

export const state = () => ({
  list: [],
});

export const mutations = {
  setDirections(state, directions) {
    state.list = directions;
  },
};

export const actions = {
  async getDirections({ commit }) {
    try {
      const currentConfig = config(this.$config.COUNTRY);
      const response = await this.$axios({
        method: 'GET',
        url: `${currentConfig.current.CMS_REST_API}/public/v2/directions/`,
      });

      if (response.data.error) {
        throw new Error(
          `An error occurred while executing the query (${response.data.error})`,
        );
      }

      commit('setDirections', response.data.data.data);
      return response.data.data.data;
    } catch (error) {
      console.error(`error`, error);
      return false;
    }
  },
};
