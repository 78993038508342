import Vue from 'vue';
import config from '@/config/countries';

export const state = () => ({
  list: [],
  allList: [],
  filterFindedTopics: {},
});

export const mutations = {
  setTopics(state, topics) {
    state.list = topics;
  },
  setAllTopics(state, topics) {
    state.allList = topics;
  },
  restoreTopics(state) {
    state.list = [...state.allList];
  },
  toggleSelection(state, topicId) {
    const topicIndex = state.list.findIndex((i) => i.id === topicId);
    if (topicIndex !== -1) {
      const topic = state.list[topicIndex];
      Vue.set(state.list[topicIndex], '_selected', !topic._selected);
    }
  },
  clearSelectedButDisabledByDirection(state) {
    state.allList = state.allList.map((i) => ({
      ...i,
      _selectedButDisabledByDirection: false,
    }));
  },
  setSelectedButDisabledByDirection(state, { slug, status }) {
    const index = state.allList.findIndex((i) => i.slug === slug);
    if (index !== -1) {
      Vue.set(state.allList[index], '_selectedButDisabledByDirection', status);
    }
  },
};

export const actions = {
  async getTopics({ commit }, filter) {
    try {
      const currentConfig = config(this.$config.COUNTRY);
      const response = await this.$axios({
        method: 'get',
        url: `${currentConfig.current.CMS_REST_API}/public/v2/topics`,
        params: {
          sortBySortAndName: true,
          ...filter,
        },
      });

      commit('setTopics', response.data.data);
      if (!filter) {
        commit('setAllTopics', response.data.data);
      }
      return response.data.data;
    } catch (error) {
      return false;
    }
  },
};
