import axios from 'axios';
import getCookie from '@/utils/getCookie';
import config from '../config/countries';
import * as Sentry from '@sentry/vue';

// TODO: user auth

export const state = () => ({
  tags: [],
  tagsLoaded: false,
});

export const actions = {
  async getTags({ commit, dispatch, state }) {
    const cookies = process.browser ? document.cookie : undefined;
    const aToken = await dispatch('getAToken', { cookies });
    const currentConfig = config(this.$config.COUNTRY);
    const LMS_REST_API = currentConfig.current.LMS_REST_API;

    if (!aToken) {
      commit('setTags', []);
      return [];
    }

    if (state.tagsLoaded) return state.tags;
    if (this.$app.user) {
      try {
        const response = await this.$axios({
          method: 'get',
          url: `${LMS_REST_API}/users/self/tags`,
          headers: {
            Authorization: `Bearer ${aToken}`,
          },
        });

        commit('setTags', response?.data || []);

        return state.tags;
      } catch (error) {
        console.error(error);

        return state.tags;
      }
    } else {
      commit('setTags', []);

      return state.tags;
    }
  },
  logout({ commit }) {
    commit('removeAccessTokenCookie');
    Sentry.setUser(null);
  },
  async logoutFromLms() {
    const currentConfig = config(this.$config.COUNTRY);
    const LMS_REST_API = currentConfig.current.LMS_REST_API;
    await axios.post(`${LMS_REST_API}/auth/logout`, null, {
      withCredentials: true,
    });
    Sentry.setUser(null);
  },
  async refreshToken({ dispatch }, { LMS_REST_API }) {
    try {
      const response = await axios.post(
        `${LMS_REST_API}/auth/refresh-tokens`,
        null,
        { withCredentials: true },
      );
      return response?.data?.token;
    } catch (e) {
      if (e?.response?.status === 401) {
        await dispatch('logoutFromLms');
      }
      throw e;
    }
  },
  async getAToken({ dispatch }, { cookies }) {
    const aToken = getCookie({ name: 'a_token', cookies });

    try {
      if (!aToken) {
        return '';
      }

      // Если время жизни токена не истекло
      // просто вернуть его
      const isATokenValid = validateToken.bind(this)(aToken);
      if (isATokenValid) return aToken;

      // Если время жизни токена истекло - нужно получить новый
      // через функцию рефреша
      const currentConfig = config(this.$config.COUNTRY);
      const LMS_REST_API = currentConfig.current.LMS_REST_API;
      const refreshedToken = await dispatch('refreshToken', { LMS_REST_API });

      if (!refreshedToken) {
        dispatch('logout');
        return '';
      }

      return refreshedToken;
    } catch (e) {
      dispatch('logout');
      console.error(e);
    }
  },
};

export const mutations = {
  setTags(state, tags) {
    state.tags = tags;
    state.tagsLoaded = true;
  },
  removeAccessTokenCookie() {
    if (process.client) {
      document.cookie =
        'a_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
  },
};

function validateToken(token) {
  const payload = parseJwtSsr(token);

  return payload.exp >= Date.now() / 1000;
}

function parseJwtSsr(token) {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}
