const API = {
  REST_API: {
    CMS_REST_API: 'http://localhost:3000',
    BILLING_REST_API: 'http://localhost:3002',
    LMS_REST_API: 'https://api.lms.ebaconline.com.br',
  },
  ENDPOINTS: {
    // Url-адрес до сервера метрик
    METRICS_URL: 'https://metrics.ebac.dev/metrics',
    // Url-адрес до лямбды, которая обращается к CIO
    CUSTOMER_IO_URL:
      'https://fkwefind93.execute-api.sa-east-1.amazonaws.com/default/billing_customer-io_get-customer-attributes',
    // Lambda, которая возвращает cloudfront info о пользователе
    cloudFrontInfoUrl: '',
    getPaymentLink: '',
    getDetailWithPaymentLink: '',
    webinarVisitorEventsUrl: '',
    webinarsCreateLeadUrlAws: '',
    showcaseApiUrl: '',
    checkSubscriptionsLink: '',
    leadFormUrl: '',
    saleUrl: '',
    promocodeUrl: '',
    wordPressPosts: '',
    feedbackUrl: '',
  },
};

const SECURITY = {
  GRECAPTCHA: {
    // Публичный ключ от рекапчи. Подставляется в Head Scripts
    grecaptchaPublicKey: '',
    grecaptchaEnabled: false,
  },
};

const ANALYTICS = {
  // ab эксперименты
  // 'black-friday': {
  //   defaultValue: 'type-one',
  //   page: '/cursos',
  // },
  experiments: null,
  gtm: {
    debug: false,
    id: '',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
    respectDoNotTrack: false,
  },
};

const LOCALIZATION = {
  I18N: {
    localeName: 'pt-br',
    utcOffset: -3,
    message: {},
    numberFormat: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
      },
    },
    defaultPhoneMask: 'bm',
  },
  SEO: {
    hreflang: '',
    href: '',
    code: '',
  },
};

const MARKETING = {
  SALE: {
    // Slug реферальных страниц
    saleReferral: [],
    // Slug распродажи, на котором будет отображено колесо фортуны
    fortuneWheelSlug: [],
    /**
     * Список распродаж, для которых автоматически активируется промокод
     * Если readonly: true - активированный купон нельзя стереть
     * или изменить
     *
     * Пример:
     * {
     *  saleSlug: 'junho-da-virada',
     *  code: '230613_dvefp4cf',
     *  readonly: false,
     * },
     * */
    autoFillPromoCodeForSales: [],
    // Images берутся из assets/images/saleNew/unique/<file>
    saleNewImages: [],
  },
  saleBanner: {
    endDate: '', // пример 2022-08-27T02:59:00Z - до 26 августа включительно
    eternal: false, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '', // "110923" название распродажи, формат ddmmyy
    link: '', // ссылка на распродажу
  },
  sourceId: {
    index: null,
    sale: null,
    saleBasket: null,
  },
};

const COMMUNICATION = {
  SOCIAL: {
    phone: '',
    phoneLink: '',
    whatsApp: '',
    whatsAppLink: '',
    email: '',
    emailLink: '',
    instagramLink: '',
    facebookLink: '',
    youtubeLink: '',
    linkedInLink: '',
    tiktok: '',
  },
  WEBINARS: {
    withWebinars: false,
    webinarsUrl: '',
    autoWebinarLoginLinkAws:
      'https://external-api.ebac.app/axl-autowebinar-subscription-processing',
  },
  BLOG: {
    withBlog: false,
    tildaBlog: false,
    blogUrl: '',
    getBlogsUrl: '',
    blogLinks: [],
  },
  B2B: {
    b2bLink: '',
    b2bLinks: {
      corporateEducation: { url: '' },
      challenges: { url: '' },
    },
  },
  LINKS: {
    externalVacanciesLink: '',
    ourStudentsLink: '',
    massMediaLink: '',
    studentReviewsLink: '',
    EBACInMedia: '',
    // URL главной страницы
    lmsUrl: '',
    // URL страницы login
    lmsLoginUrl: '',
  },
};

const LEGAL = {
  DOCUMENTS: {
    documents: {
      serviceAgreementAndPrivacyPolicy: '',
      linkToMx: {},
      privacyNoticeStudents: '',
      privacyNoticeTeachers: '',
      termsAndConditions: '',
      cursos: '',
    },
    footerDocuments: [],
    formDocument: '',
  },
  PAGE_404: {
    additionalText404: false,
    links404: [],
  },
};

const HEAD = {
  // Global head
  head: {
    head: {
      lang: '',
      meta: [],
      link: [],
      script: [],
      __dangerouslyDisableSanitizers: ['script'],
    },
  },
  bodyScripts: [],
  // head скрипты страницы "центр карьеры студентов"
  careerCenterStudentsScripts: [],
};

export default {
  ...API.REST_API,
  ...API.ENDPOINTS,

  ...SECURITY.GRECAPTCHA,

  ...ANALYTICS,

  ...LOCALIZATION.I18N,
  ...LOCALIZATION.SEO,

  ...MARKETING.SALE,
  saleBanner: MARKETING.saleBanner,
  sourceId: MARKETING.sourceId,

  ...COMMUNICATION.SOCIAL,
  ...COMMUNICATION.WEBINARS,
  ...COMMUNICATION.BLOG,
  ...COMMUNICATION.B2B,
  ...COMMUNICATION.LINKS,

  ...LEGAL.DOCUMENTS,
  ...LEGAL.PAGE_404,

  ...HEAD,

  // Домен страны
  domain: '',
  // Домен, который заменяет домен у некоторых ссылок
  // приходящих из cms
  newDomainName: '',
  // Наличие гарантии трудоустройства
  jobGuarantee: false,
  // Отображать ли checkbox принятия политики конфиденциальности
  // у форм
  formWithCheckbox: false,
};
