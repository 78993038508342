import message from '../../i18n/es-mx';

const API = {
  REST_API: {
    CMS_REST_API: 'https://cms.ebac.mx/api',
    BILLING_REST_API: 'https://billing.ebac.app',
    LMS_REST_API: 'https://api.lms.ebac.ar',
  },
  ENDPOINTS: {
    METRICS_URL: 'https://metrics.ebac.dev/metrics',
    CUSTOMER_IO_URL:
      'https://fkwefind93.execute-api.sa-east-1.amazonaws.com/default/billing_customer-io_get-customer-attributes',
    // Lambda, которая возвращает cloudfront info о пользователе
    cloudFrontInfoUrl: 'https://widgets.ebac.app/cloudfront-viewer-info',
    webinarVisitorEventsUrl:
      'https://wisggajuuczifmvdtlzwluwpei0urtta.lambda-url.sa-east-1.on.aws/webinar-visitors-webhook',
    checkSubscriptionsLink: 'https://api.lms.ebac.ar/subscriptions/is-mine',
    leadFormUrl: 'https://gdd.ebaconline.com.br/exec',
    saleUrl: 'https://gdd.ebaconline.com.br/exec',
    promocodeUrl: 'https://cms.ebac.mx/api/shopping-cart',
    feedbackUrl: 'https://gdd.ebaconline.com.br/exec',
  },
};

const ANALYTICS = {
  gtm: {
    debug: false,
    id: 'GTM-PCTL3KZ',
    autoInit: true,
    enabled: true,
    pageTracking: true,
    scriptDefer: true,
    respectDoNotTrack: false,
  },
};

const LOCALIZATION = {
  I18N: {
    // Локализация
    localeName: 'es-mx',
    utcOffset: -3,
    message,
    numberFormat: {
      currency: {
        style: 'currency',
        currency: 'ARS',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'ARS',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
      },
    },
    defaultPhoneMask: 'ar',
  },
  SEO: {
    href: 'https://ebac.ar',
  },
};

const MARKETING = {
  saleBanner: {
    endDate: '',
    eternal: false, // вечная распродажа? если да, то endDate можно не заполнять (если ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, ПН, то до ПН)
    eternalV2: false, // вечная распродажа? если да, то endDate можно не заполнять (если ПН, ВТ, СР, ЧТ, ПТ, то до ПТ / если СБ, ВС, то до ВС)
    name: '100822', // название распродажи, формат ddmmyy
    link: 'https://ebaconline.com.br/sale/junho-da-virada', // ссылка на распродажу
  },
};

const COMMUNICATION = {
  SOCIAL: {
    phone: '',
    phoneLink: '',
    whatsApp: '',
    whatsAppLink: '',
    email: '',
    emailLink: '',
    instagramLink: 'https://www.instagram.com/ebac_latam/?hl=es',
    facebookLink: 'https://www.facebook.com/EbacLATAM',
    youtubeLink: 'https://www.youtube.com/channel/UCVqYXk6kbrdcDbmPtOw9v4g',
    linkedInLink: '',
    tiktok: '',
  },
  BLOG: {
    withBlog: true,
    tildaBlog: false,
    blogUrl: 'https://ebac.ar/blog',
    getBlogsUrl: 'https://ebac.ar/api/tilda-main-blog',
    blogLinks: [
      {
        href: 'https://ebac.ar/blog#!/tab/332850082-2',
        key: 'template.design',
      },
      {
        href: 'https://ebac.ar/blog#!/tab/332850082-3',
        key: 'template.gaming',
      },
    ],
  },
  LINKS: {
    externalVacanciesLink: 'https://ebac_latam.gupy.io/',
    // URL главной страницы
    lmsUrl: 'https://lms.ebac.ar/',
    // URL страницы login
    lmsLoginUrl: 'https://lms.ebac.ar/login/',
  },
};

const LEGAL = {
  DOCUMENTS: {
    documents: {
      privacyNoticeStudents:
        'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_alumnos.pdf',
      privacyNoticeTeachers:
        'https://ebac.mx/upload/cms/ebac_aviso_de_privacidad_integral_profesores.pdf',
      termsAndConditions:
        'https://ebac.mx/upload/cms/ebac_tyc_alumnos_pag_cursos.pdf',
    },
    footerDocuments: [
      'privacyNoticeStudents',
      'privacyNoticeTeachers',
      'termsAndConditions',
    ],
    formDocument: 'privacyNoticeStudents',
  },
  PAGE_404: {
    links404: [
      {
        key: 'courses',
        link: '/cursos?types=course',
      },
      {
        key: 'professions',
        link: '/cursos?types=profession',
      },
      {
        key: 'blogArticles',
        link: 'https://ebac.ar/blog',
      },
      {
        key: 'aboutUs',
        link: '/about-us',
      },
    ],
  },
};

const HEAD = {
  // Global head
  head: {
    lang: 'es',
    meta: [
      {
        name: 'facebook-domain-verification',
        content: 'bm81ix33ewin04r43wvttup6uvf09j',
      },

      { name: 'msapplication-TileColor', content: '#ffffff' },
      { name: 'msapplication-config', content: '/favicons/browserconfig.xml' },
    ],
    link: [
      {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: '/favicon.ico',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        type: 'image/png',
        href: '/favicon-32x32.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        type: 'image/png',
        href: '/favicon-16x16.png',
      },
      {
        rel: 'manifest',
        crossOrigin: 'use-credentials',
        href: '/favicons/site.webmanifest',
      },
    ],
    script: [
      // {
      //   innerHTML: `(function(a,m,o,c,r,m){a[m]={id:"55739",hash:"1ff7300d2677990955830635bd87352e4d8646284065bebd0d2b4738baf4a888",locale:"en",inline:true,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1630666958';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`,
      //   type: 'text/javascript',
      //   async: true,
      // },
    ],
    __dangerouslyDisableSanitizers: ['script'],
  },
  bodyScripts: [],
  // head скрипты страницы "центр карьеры студентов"
  careerCenterStudentsScripts: [],
};

export default {
  ...API.REST_API,
  ...API.ENDPOINTS,

  ...ANALYTICS,

  ...LOCALIZATION.I18N,
  ...LOCALIZATION.SEO,

  saleBanner: MARKETING.saleBanner,

  ...COMMUNICATION.SOCIAL,
  ...COMMUNICATION.BLOG,
  ...COMMUNICATION.LINKS,

  ...LEGAL.DOCUMENTS,
  ...LEGAL.PAGE_404,

  ...HEAD,

  // Домен страны
  domain: 'ebac.ar',
  // Домен, который заменяет домен у некоторых ссылок
  // приходящих из cms
  newDomainName: 'https://ebac.ar',
  // Наличие гарантии трудоустройства
  jobGuarantee: false,
  // Отображать ли checkbox принятия политики конфиденциальности
  // у форм
  formWithCheckbox: false,
};
